import React, { Component } from 'react';

import Loading from '../components/Loading';
import OAuthError from './OAuthError';

import api from '../util/api';

class OAuth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false
        };
    }

    componentDidMount() {
        this.token();
    }

    parse(query) {
        try {
            const params = new URLSearchParams(query);
            return {
                state: params.get('state'),
                code: params.get('code')
            };
        } catch(e) {
            return {
                state: null,
                code: null
            };
        }
    }

    async token() {
        const result = this.parse(this.props.query);
        try {
            if (result.state.startsWith('blizzard')) {
                // doesn't work if opened in new tab
                //if (result.state !== localStorage.getItem('state.blizzard')) throw new Error('Invalid state');

                const region = result.state.substring(8, 10);
                const response = result.code && await api.get('token/blizzard/' + encodeURIComponent(region) + '/' + encodeURIComponent(result.code));
                const json = await response.json();
                if (!json.access_token) throw new Error('Invalid Token');

                localStorage.setItem('token.blizzard', JSON.stringify(json));
                this.props.history.push('/mycharacters');
            } else if (result.state.startsWith('patreon')) {
                // doesn't work if opened in new tab
                //if (result.state !== localStorage.getItem('state.patreon')) throw new Error('Invalid state');

                const response = result.code && await api.get('token/patreon/' + encodeURIComponent(result.code));
                const json = await response.json();
                if (!json.access_token) throw new Error('Invalid Token');

                localStorage.setItem('token.patreon', JSON.stringify(json));
                this.props.history.push('/mycharacters');
            } else if (result.state.startsWith('twitch')) {
                // doesn't work if opened in new tab
                //if (result.state !== localStorage.getItem('state.twitch')) throw new Error('Invalid state');

                const response = result.code && await api.get('token/twitch/' + encodeURIComponent(result.code));
                const json = await response.json();
                if (!json.access_token) throw new Error('Invalid Token');

                localStorage.setItem('token.twitch', JSON.stringify(json));
                this.props.history.push('/mycharacters');
            }
        } catch(e) {
            this.setState({ error: true });
        }
    }

    render() {
        return this.state.error ? <OAuthError /> : <Loading message="Loading..." />;
    }
}

export default OAuth;