import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCog } from '@fortawesome/free-solid-svg-icons';

import utils from '../util/utils';
import api from '../util/api';

class Card extends Component {
    constructor(props) {
        super(props);
        this.state = {
            max: null,
            realm: null
        };
    }

    componentDidMount() {
        this.lookupMax();
        this.lookupRealm();
    }

    componentDidUpdate(previous) {
        if (previous.region === this.props.region && previous.realmSlug === this.props.realmSlug) return;
        this.lookupRealm();
    }

    async lookupMax() {
        const max = await api.web('max');
        this.setState({ max });
    }

    async lookupRealm() {
        const json = await api.web('realms');
        const realm = json.realms.find(realm => realm.region.toLowerCase() === this.props.region.toLowerCase() && realm.slug === this.props.realmSlug);
        this.setState({ realm });
    }

    displayLink(link, text, rank) {
        // negative numbers indicate we went over max
        const overmax = (rank < 0);
        const query = rank ? ('?rank=' + Math.abs(rank)) : '';

        const content = <Link to={link + query} rel="nofollow">{text}{this.displayBadge(rank)}</Link>;
        if (overmax) {
            return <Tooltip title="Exact ranking is too low to display on character page">{content}</Tooltip>;
        } else {
            return content;
        }
    }

    displayBadge(rank) {
        let overmax = false;
        if (rank < 0) {
            // negative numbers indicate we went over max
            rank = -rank;
            overmax = true;
        }
        if (rank > 99999) {
            // want to limit screen size used
            rank = 99999;
            overmax = true;
        }

        if (rank || (rank === 0)) {
            try {
                rank = rank.toLocaleString();
            } catch (e) {
                rank = '?';
            }
        } else {
            rank = <FontAwesomeIcon icon={faCog} spin />;
        }

        return <span className="badge badge-primary badge-large ml-1">{rank}{overmax ? '+' : null}</span>;
    }

    subtext() {
        if (typeof this.props.myscore === 'number') {
            if (this.props.score === this.props.myscore) return <span>&nbsp;</span>;
            return utils.formatScore('', this.props.myscore) + ' on this character';
        } else {
            const percent = utils.formatPercent(this.props.category, this.props.score, this.state.max);
            return percent || <span>&nbsp;</span>;
        }
    }

    tooltip() {
        const percent = utils.formatPercentAndScore(this.props.category, this.props.score, this.state.max);

        return (
            <>
                <div>{utils.getDescription(this.props.category)}</div>
                {percent ? <div className="mt-3 text-center">{percent}</div> : null}
                {(typeof this.props.ingame === 'number') ? <div className="mt-3">The in-game Mounts tab shows {utils.formatScore('', this.props.ingame)} for this character.</div> : null}
                <div className="mt-3"><i>Click for historical progress</i></div>
            </>
        );
    }

    render() {
        if (this.props.selectedCategory && this.props.selectedCategory !== this.props.category) return null;

        const size = this.props.split ? 'col-12' : 'col-12 col-md-12 col-lg-6 col-xl-4';

        return (
            <div className={size + ' my-3'}>
                <div className="card rounded">
                    <div className="card-body m-0 p-0 row">
                        <div className="p-0 col-12 col-sm-6">
                            <Tooltip title={this.tooltip()}>
                                <Link to={this.props.navUrl + '/' + this.props.category + '-details'} className="btn h-100 d-flex">
                                    <div className="m-auto">
                                        {utils.getFriendlyCategoryName(this.props.category, false)}<FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                                        <h1 className="text-nowrap">{utils.formatScore(this.props.category, this.props.score)}</h1>
                                        {this.subtext()}
                                    </div>
                                </Link>
                            </Tooltip>
                        </div>
                        <div className="text-right small rankings p-0 col-12 col-sm-6">
                            <div className="p-2">{this.displayLink('/leaderboards/' + this.props.category, 'World', this.props.ranking && this.props.ranking.world)}</div>
                            <div className="p-2">{this.displayLink('/leaderboards/' + this.props.category + '/' + encodeURIComponent(this.props.region.toLowerCase()), this.props.region.toUpperCase(), this.props.ranking && this.props.ranking.region)}</div>
                            {(this.state.realm && this.state.realm.connected && this.state.realm.connected.length > 1) ? <div className="p-2">{this.displayLink('/leaderboards/' + this.props.category + '/' + encodeURIComponent(this.props.region.toLowerCase()) + '/connected-' + encodeURIComponent(this.props.realmSlug), 'Connected', this.props.ranking && this.props.ranking.connected)}</div> : null}
                            <div className="p-2">{this.displayLink('/leaderboards/' + this.props.category + '/' + encodeURIComponent(this.props.region.toLowerCase()) + '/' + encodeURIComponent(this.props.realmSlug), this.props.realm, this.props.ranking && this.props.ranking.realm)}</div>

                            {(this.state.realm && this.state.realm.connected && this.state.realm.connected.length > 1) ? null : <div className="p-2">&nbsp;</div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Card;