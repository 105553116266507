const base = 'https://api.dataforazeroth.com/';

const cache = {};
const cacheable = new Set([ 'realms', 'max' ]);

const H = [ 'A', 'c', 'c', 'e', 'p', 't' ];
const V = [ 't', 'e', 'x', 't', '/', 'j', 's', 'o', 'n', ';', ' ', 'c', 'h', 'a', 'r', 's', 'e', 't', '=', 'i', 's', 'o', '-', '8', '8', '5', '9', '-', '1' ];

function headers(endpoint) {
    if (endpoint[0] !== '/') endpoint = '/' + endpoint;

    const header = H.join('');
    const value = V.join(''); //endpoint.split('').reduce((a, c) => a + c.codePointAt(), 0);
    return { [header]: value };
}

async function get(endpoint) {
    try {
        const params = {
            headers: headers(endpoint)
        };
        return await fetch(base + endpoint, params);
    } catch(e) {
        // often happens due to CORS, so fake an HTTP error code
        return { status: 500 };
    }
}

async function post(endpoint, payload) {
    try {
        const params = {
            method: 'post',
            body: JSON.stringify(payload),
            headers: headers(endpoint)
        };
        return await fetch(base + endpoint, params);
    } catch(e) {
        // often happens due to CORS, so fake an HTTP error code
        return { status: 500 };
    }
}

// helps fetch S3 files that are outside source code
async function web(key) {
    // first make sure version info is loaded
    if (!cache.version) cache.version = _version(); // promise
    const version = await cache.version;

    // check cache AFTER previous await
    if (cache[key]) return cache[key];

    // cache miss so do a lookup
    let url = version[key];
    if (!url) throw new Error('/' + key + ' API lookup failed');
    if (window.location.hostname === 'dev.dataforazeroth.com') url = 'https://www.dataforazeroth.com' + url;

    const json = _web(url); // promise
    if (cacheable.has(key)) cache[key] = json;
    return json;
}

async function _version() {
    const response = await get('version');
    if (response.status !== 200) throw new Error('/version API call failed');

    return response.json();
}

async function _web(url) {
    const response = await fetch(url);
    if (response.status !== 200) throw new Error(url + ' API call failed');

    return response.json();
}

const api = { get, post, web };
export default api;