function Favorite(key, limit) {
    this.key = key;
    this.limit = limit;

    this.array = null;

    this.getFavorites = () => {
        if (!this.array) {
            this.array = [];
            const str = localStorage.getItem(this.key);
            if (str) this.array = JSON.parse(str);
        }

        return this.array;
    };
    
    this.setFavorites = (json) => {
        localStorage.setItem(this.key, JSON.stringify(json));
        this.array = json;
    };
    
    this.isFavorite = (region, realm, character) => {
        const favorites = this.getFavorites();

        for (let i = 0; i < favorites.length; i++) {
            if (favorites[i].region === region && favorites[i].realm === realm && favorites[i].character === character) return true;
        }

        return false;
    };
    
    this.addFavorite = (region, realm, character, c, thumbnail, hidden) => {
        const favorites = this.getFavorites();
        
        if (favorites.length >= this.limit) {
            alert('Sorry, you have too many favorites selected.');
            return false;
        }
        
        const entry = { region, realm, character, 'class': c, thumbnail };
        if (hidden) entry.hidden = hidden;
        favorites.push(entry);
        this.setFavorites(favorites);
        
        return true;
    };
    
    this.updateFavorite = (region, realm, character, c, thumbnail, hidden) => {
        const favorites = this.getFavorites();
        for (let i = 0; i < favorites.length; i++) {
            if (favorites[i].region !== region || favorites[i].realm !== realm || favorites[i].character !== character) continue;
            
            if (c) favorites[i].class = c;
            if (thumbnail) favorites[i].thumbnail = thumbnail;
            if (hidden) {
                favorites[i].hidden = hidden;
            } else if (hidden !== undefined) {
                delete favorites[i].hidden;
            }
        }
        this.setFavorites(favorites);
    };
    
    this.removeFavorite = (region, realm, character) => {
        const favorites = this.getFavorites();
        for (let i = 0; i < favorites.length; i++) {
            if (favorites[i].region !== region || favorites[i].realm !== realm || favorites[i].character !== character) continue;
            favorites.splice(i, 1);
            break;
        }
        this.setFavorites(favorites);
    };
}

const stores = {
    favorites: new Favorite('favorites', 50)
};

function get(key, limit) {
    if (!stores[key]) stores[key] = new Favorite(key, limit);
    return stores[key];
}

const favorite = { get };
export default favorite;