import React, { Component } from 'react';

const international = (1.2).toLocaleString().includes(',');
const decimal = international ? ',' : '.';
const regex = international ? /[^\d,]/g : /[^\d.]/g;

export default class NumberInput extends Component {
    onChange = (event) => {
        // remove invalid characters
        let text = event.currentTarget.value.replace(regex, '');

        // make sure there's only one decimal
        text = text.replace(decimal, 'X').replaceAll(decimal, '').replace('X', decimal);

        // substitute decimal to parse float
        const number = parseFloat(text.replaceAll(',', '.'));

        this.props.onChange(text, number);
    }

    render() {
        return <input {...this.props} type="text" size={3} onChange={this.onChange} />;
    }
}