import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import Collection from './Collection';
import Section from '../components/Section';

export default class Browse extends Component {
    render() {
        document.title = 'Browse Collections & Rarity | Data for Azeroth | World of Warcraft Leaderboards for Collectors';

        return (
            <Section title="Browse Collections &amp; Rarity">
                <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' mb-3 mr-3 flex-wrap'}>
                    <NavLink to="/collections/achievements" className="btn btn-primary">Achievements</NavLink>
                    <NavLink to="/collections/mounts" className="btn btn-primary">Mounts</NavLink>
                    <NavLink to="/collections/pets" className="btn btn-primary">Pets</NavLink>
                    <NavLink to="/collections/titles" className="btn btn-primary">Titles</NavLink>
                    <NavLink to="/collections/reputations" className="btn btn-primary">Reputations</NavLink>
                    <NavLink to="/collections/recipes" className="btn btn-primary">Recipes</NavLink>
                    <NavLink to="/collections/quests" className="btn btn-primary">Quests</NavLink>
                    <NavLink to="/collections/toys" className="btn btn-primary">Toys</NavLink>
                    <NavLink to="/collections/appearances" className="btn btn-primary">Appearances</NavLink>
                </div>
                <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' mb-3 flex-wrap'}>
                    <NavLink to="/collections/heirlooms" className="btn btn-primary">Heirlooms</NavLink>
                    <NavLink to="/collections/hunterpets" className="btn btn-primary">Hunter Pets</NavLink>
                    <NavLink to="/collections/soulshapes" className="btn btn-primary">Soulshapes</NavLink>
                    <NavLink to="/collections/manuscripts" className="btn btn-primary">Manuscripts</NavLink>
                    <NavLink to="/collections/skips" className="btn btn-primary">Raid Skips</NavLink>
                </div>
                <Collection showProgress={false} showCollected={false} onToggle={this.props.onToggle} collection={this.props.collection} />
            </Section>
        );
    }
}