import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import './Loading.scss';

class Loading extends Component {
    render() {
        if (this.props.message) {
            return <span className="loading text-center"><span className="p-3 bar rounded"><FontAwesomeIcon icon={faCog} spin /> {this.props.message}</span></span>;
        } else {
            return null;
        }
    }
}

export default Loading;