import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import Character from './Character';

class Split extends Component {
    render() {
        return (
            <div>
                {window.bootstrap.md.matches ? null : <div className="mx-3 my-0 alert alert-warning"><FontAwesomeIcon icon={faExclamationTriangle} /> Character comparison is not optimized for your screen size</div>}
                <div className="row m-0 p-0">
                    <div className="col-6 p-0"><Character split="left" region={this.props.region1} realm={this.props.realm1} character={this.props.character1} onToggle={this.props.onToggle} collection={this.props.collection1} /></div>
                    <div className="col-6 p-0"><Character split="right" region={this.props.region2} realm={this.props.realm2} character={this.props.character2} onToggle={this.props.onToggle} collection={this.props.collection2} /></div>
                </div>
            </div>
        );
    }
}

export default Split;