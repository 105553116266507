import React, { Component } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class MainButton extends Component {
    handleClick = (event) => {
        if (event) event.preventDefault(); // prevent form submission
        this.props.onClick(this.props.region, this.props.realm, this.props.character);
        return false;
    }

    render() {
        return (
            <form method="post" onSubmit={this.handleClick}>
                <button type="submit" disabled={this.props.disabled} className="btn btn-primary">
                    <Tooltip title={this.props.tooltip}>
                        <span><FontAwesomeIcon icon={this.props.icon} /></span>
                    </Tooltip>
                </button>
            </form>
        );
    }
}