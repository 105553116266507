import React, { Component } from 'react';

import Section from './components/Section';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedditAlien, faDiscord, faBattleNet } from '@fortawesome/free-brands-svg-icons';

import Loading from './components/Loading';
import api from './util/api';
import utils from './util/utils';

class Tickets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tickets: null
        };
    }

    componentDidMount() {
        this.lookup();
    }

    async lookup() {
        const tickets = await api.web('tickets');

        // sort blizzard issues to the top
        tickets.issues.sort((a, b) => utils.collator.compare((a.fields.customfield_10724 ? 'A' : 'Z') + a.fields.summary, (b.fields.customfield_10724 ? 'A' : 'Z') + b.fields.summary));

        this.setState({ tickets });

        // scroll to correct ticket
        const hash = document.location.hash?.substring(1);
        if (hash) {
            const fn = () => utils.scrollTo(hash);
            window.setTimeout(fn, 250); // workaround for page loading/shifting
        }
    }

    render() {
        document.title = 'Issue Tracker | Data for Azeroth | World of Warcraft Leaderboards for Collectors';

        const rows = [];

        if (this.state.tickets) {
            for (let ticket of this.state.tickets.issues) {
                rows.push(
                    <tr key={ticket.key}>
                        <td id={ticket.key} className="text-nowrap"><a href={'#' + ticket.key}>{ticket.key}</a></td>
                        <td>
                            {ticket.fields.summary}
                            {ticket.fields.customfield_10724 ? <a href={ticket.fields.customfield_10724} target="external" className="btn btn-primary btn-sm ml-3"><FontAwesomeIcon icon={faBattleNet} className="mr-1" />Reported to Blizzard</a> : null}
                        </td>
                    </tr>
                );
            }
        } else {
            rows.push(<tr key="L"><td></td><td><Loading message="Loading..." />&nbsp;</td></tr>);
        }

        return (
            <div style={{ marginBottom: '100vh' }}> {/* extra space for anchor scrolling */}
                <Section title="Issue Tracker" subtitle="Everything Will Get Fixed Soon&trade;" icon="https://render.worldofwarcraft.com/us/icons/56/inv_misc_punchcards_yellow.jpg">
                    <p>
                        This is a read-only copy of the feedback, suggestions and bugs being tracked for <i>Data for Azeroth</i>.
                        I am committed to fixing bugs and maintaining patches as soon as possible, but cannot make any promises when new features will be built.
                        I try to listen to community feedback but priority will be determined at my own discretion.
                    </p>
                    <p className="mb-0">
                        To submit your own feedback, please post in
                        either <a href="https://www.reddit.com/r/dataforazeroth/" target="external"><FontAwesomeIcon icon={faRedditAlien} className="mr-1" />Reddit</a> or <a href="https://discord.gg/7Mmjxdh" target="external"><FontAwesomeIcon icon={faDiscord} className="mr-1" />Discord</a>.
                        Thank you!
                    </p>
                </Section>
                <div className="m-3 card">
                    <table className="table table-hover m-0">
                        <thead className="thead-dark">
                            <tr>
                                <th width="2%">#</th>
                                <th width="98%">Issue</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Tickets;