import React, { Component } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import FavoriteDown from './FavoriteDown';

export default class FavoriteDownButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            favorites: false
        };
    }

    toggleFavorites = (event) => {
        this.setState({ favorites: this.state.favorites ? false : event.currentTarget });
    }

    closeFavorites = () => {
        this.setState({ favorites: false });
    }

    render() {
        return (
            <>
                <Tooltip title="View Favorite Characters"><button type="button" className="btn btn-primary" onClick={this.toggleFavorites}><FontAwesomeIcon icon={this.state.favorites ? faChevronUp : faChevronDown} /></button></Tooltip>
                <Popper open={Boolean(this.state.favorites)} anchorEl={this.state.favorites} placement="bottom-end" style={{ minWidth: '25em'}}>
                    <ClickAwayListener onClickAway={this.closeFavorites}>
                        <FavoriteDown />
                    </ClickAwayListener>
                </Popper>
            </>
        );
    }
}