import React, { Component } from 'react';

import Section from './components/Section';
import Question from './components/Question';

class Privacy extends Component {
    render() {
        document.title = 'Privacy Policy | Data for Azeroth | World of Warcraft Leaderboards for Collectors';

        return (
            <Section title="Privacy Policy" subtitle="Updated November 25, 2019">
                <Question id="whats-collected" title="What information does Data for Azeroth collect?">
                <p>
                    <i>Data for Azeroth</i> collects information about the names, progress, achievements, and in-game collectables of virtual characters
                    within the <i>World of Warcraft</i> video game. The information collected is publicly available on the <i>World of Warcraft Armory</i> web site.
                    This is not tied to or correlated with any real world information. <i>Data for Azeroth</i> does
                    not collect any personal information from visitors.
                </p>
                <p>
                    <i>Data for Azeroth</i> uses <i>Google Analytics</i> to track visitor counts and assist with capacity planning. <i>Google Analytics</i> provides its own <a href="https://policies.google.com/privacy" target="external">Privacy Policy</a>.
                </p>
                </Question>

                <Question id="collection-method" title="How is this information collected?">
                <p>
                    Information about virtual characters is obtained using the web-based APIs provided by <i>Blizzard Entertainment</i>.
                </p>
                </Question>

                <Question id="storage" title="How is this information stored and protected?">
                <p>
                    Information about virtual characters is stored within the <i>Amazon Web Services</i> cloud computing platform. <i>Data for Azeroth</i> follows
                    industry best practices to keep data secure and protect against unauthorized access, including but not limited to appropriate
                    use of encryption, firewalls, strong passwords, and routine rotation of keys and passwords.
                </p>
                </Question>

                <Question id="access" title="Who has access to this information?">
                <p>
                    <i>Data for Azeroth</i> does not sell or otherwise share any information with third parties. Visitor information collected by <i>Google Analytics</i> is covered under its own privacy policy.
                </p>
                </Question>

                <Question id="updates" title="How is the privacy policy updated?">
                <p>
                    <i>Data for Azeroth</i> will update this privacy policy on an as-needed basis, primarily to comply with <i>Blizzard Entertainment</i> policies.
                    Updates will be posted to this page with a date at the top.
                </p>
                </Question>
            </Section>
        );
    }
}

export default Privacy;
