import React from 'react';

import Section from '../components/Section';

const SHOWERROR = false;

function CharactersError(props) {
    return (
        <Section title="Error Loading Your Characters">
            Please make sure that:
            <ul>
                <li>You have chosen the correct region when logging in.</li>
                <li>You have granted <i>Data for Azeroth</i> the necessary permissions.
                    <div className="small">
                        You can confirm permissions by going to your <a href="https://account.blizzard.com/connections#authorized-applications" target="external">Blizzard Account</a> (<i>Account Settings &gt; Connections &gt; Authorized Applications</i>)
                        and make sure <i>Data for Azeroth</i> can access <i>Your Battle.net Account ID and BattleTag</i> and <i>Your World of Warcraft profile</i>.
                        If either of these permissions are missing, you can remove the application, and then try logging in again.
                    </div>
                </li>
                <li>You try clicking <i>Logout</i> and then <i>Login</i> again.</li>
            </ul>

            Sometimes there are temporary errors with the Blizzard API. If all else fails, please wait a few hours and try again.

            {SHOWERROR ? <pre className="small mt-5" style={{ whiteSpace: 'pre-wrap' }}>{props.details}</pre> : null}
        </Section>
    );
}

export default CharactersError;