import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';

import MuiTheme from './MuiTheme';
import Header from './navigation/Header';
import Footer from './navigation/Footer';
import ScrollToTop from './components/ScrollToTop';
import Loading from './components/Loading';

import bg from './images/background.jpg';
import './bootstrap.scss';

import Character from './characters/Character';
import Collections from './collections/Collections';
import Leaderboard from './leaderboards/Leaderboard';
import Split from './characters/Split';
import Browse from './collections/Browse';

import Privacy from './Privacy';
import Team from './teams/Team';
import OAuth from './login/OAuth';
import SolarCollector from './minigames/SolarCollector';
import Brand from './Brand';
import Tickets from './Tickets';

const About = React.lazy(() => import('./About'));
const Calendar = React.lazy(() => import('./Calendar'));
const Calculator = React.lazy(() => import('./calculator/CalculatorPage'));
const Statistics = React.lazy(() => import('./statistics/Statistics'));
const ChangeLog = React.lazy(() => import('./collections/ChangeLog'));
const GrantsLog = React.lazy(() => import('./collections/GrantsLog'));
const Stream = React.lazy(() => import('./stream/Stream'));
const Item = React.lazy(() => import('./collections/Item'));
const TwitchDrops = React.lazy(() => import('./TwitchDrops'));

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            size: 'xs'
        };

        window.bootstrap = {
            xl: window.matchMedia('(min-width: 1200px)'),
            lg: window.matchMedia('(min-width: 992px)'),
            md: window.matchMedia('(min-width: 768px)'),
            sm: window.matchMedia('(min-width: 576px)')
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        if (window.bootstrap.xl.matches) {
            if (this.state.size !== 'xl') this.setState({ 'size': 'xl' });
        } else if (window.bootstrap.lg.matches) {
            if (this.state.size !== 'lg') this.setState({ 'size': 'lg' });
        } else if (window.bootstrap.md.matches) {
            if (this.state.size !== 'md') this.setState({ 'size': 'md' });
        } else if (window.bootstrap.sm.matches) {
            if (this.state.size !== 'sm') this.setState({ 'size': 'sm' });
        } else {
            if (this.state.size !== 'xs') this.setState({ 'size': 'xs' });
        }
    }

    close = () => {
        try {
            // try to close any open Wowhead tooltips
            window.WH.Tooltips.clearTouchTooltip();
        } catch (ignored) {}
    }

    suspense = (child) => {
        return <Suspense fallback={<Loading message="Loading..." />}>{child}</Suspense>;
    }

    routeHeader = (props) => {
        return <Header history={props.history} />;
    }

    routeLeaderboards = (props) => {
        const params = new URLSearchParams(props.location.search);
        return <Leaderboard category={props.match.params.category?.toLowerCase()} region={props.match.params.region} realm={props.match.params.realm} guild={props.match.params.guild} rank={params.get('rank')} history={props.history} />;
    }

    routeCollectionsSplit = (props) => {
        return <Collections mode="compare" region1={props.match.params.region1} realm1={props.match.params.realm1} character1={props.match.params.character1} region2={props.match.params.region2} realm2={props.match.params.realm2} character2={props.match.params.character2} tab={props.match.params.tab || 'account-rankings'} render={this.routeSplit} />;
    }

    routeSplit = (props) => {
        return <Split region1={props.region1} realm1={props.realm1} character1={props.character1} collection1={props.collection1} region2={props.region2} realm2={props.realm2} character2={props.character2} collection2={props.collection2} onToggle={props.handleToggle} />;
    }

    routeCollectionsCharacter = (props) => {
        return <Collections region1={props.match.params.region} realm1={props.match.params.realm} character1={props.match.params.character} tab={props.match.params.tab || 'account-rankings'} render={this.routeCharacter} />;
    }

    routeCharacter = (props) => {
        return <Character region={props.region1} realm={props.realm1} character={props.character1} onToggle={props.handleToggle} collection={props.collection1} />;
    }

    routeCollectionsLog = (props) => {
        const params = new URLSearchParams(props.location.search);
        return this.suspense(<ChangeLog category={props.match.params.category} offset={params.get('offset')} history={props.history} />);
    }

    routeGrants = (props) => {
        return this.suspense(<GrantsLog history={props.history} />);
    }

    routeCollectionsItem = (props) => {
        return <Collections category={props.match.params.category} id={props.match.params.id} history={props.history} render={this.routeItem} />;
    }

    routeItem = (props) => {
        return this.suspense(<Item category={props.category} id={props.id} collection={props.collection1} history={props.history} />);
    }

    routeCollectionsBrowse = (props) => {
        return <Collections category={props.match.params.category} render={this.routeBrowse} />;
    }

    routeStatistics = (props) => {
        const category = props.match.params.category;
        const subcategory = props.match.params.subcategory;
        if (category === 'realms' && !subcategory) return <Redirect to="/stats/realms/eu" />;
        return this.suspense(<Statistics category={category} subcategory={subcategory} />);
    }

    routeBrowse = (props) => {
        return <Browse category={props.category} onToggle={props.handleToggle} collection={props.collection1} />;
    }

    routeOAuth = (props) => {
        return <OAuth history={props.history} query={props.location.search} />;
    }

    routeRoot = (props) => {
        return <Redirect to={(props.location.hash || '/leaderboards').replace('#', '')} />;
    }

    routeAbout = () => {
        return this.suspense(<About />);
    }

    routeCalculator = () => {
        return this.suspense(<Calculator />);
    }

    routeStream = () => {
        return this.suspense(<Stream />);
    }

    routeTwitchDrops = () => {
        return this.suspense(<TwitchDrops />);
    }

    content = () => {
        const style = {};
        if (window.bootstrap.md.matches) style.backgroundImage = 'url(' + bg + ')';

        return (
            <div className="d-flex flex-column app" style={style} onClick={this.close}>
                <ScrollToTop />
                <Route path="/" render={this.routeHeader} />
                <div style={{ minHeight: '100vh' }}>
                    <Switch>
                        <Route path="/leaderboards/:category/:region?/:realm?/:guild?" render={this.routeLeaderboards} />
                        <Route path="/leaderboards"><Redirect to={'/leaderboards/completion-score' + (localStorage.getItem('leaderboards.showObtainable') === 'true' ? '-obtainable' : '')} /></Route>

                        <Route path="/characters/:region1/:realm1/:character1/compare/:region2/:realm2/:character2/:tab" render={this.routeCollectionsSplit} />
                        <Route path="/characters/:region1/:realm1/:character1/compare/:region2/:realm2/:character2" render={this.routeCollectionsSplit} />
                        <Route path="/characters/:region/:realm/:character/:tab" render={this.routeCollectionsCharacter} />
                        <Route path="/characters/:region/:realm/:character" render={this.routeCollectionsCharacter} />

                        <Route path="/collections/:category/log" render={this.routeCollectionsLog} />
                        <Route path="/collections/:category/:id" render={this.routeCollectionsItem} />
                        <Route path="/collections/:category" render={this.routeCollectionsBrowse} />
                        <Route path="/collections"><Redirect to="/collections/achievements" /></Route>

                        <Route path="/stats/:category/:subcategory?" render={this.routeStatistics} />
                        <Route path="/stats"><Redirect to="/stats/summary" /></Route>

                        <Route path="/redirect" render={this.routeOAuth} />
                        <Route path="/favorites"><Team from="favorites" /></Route>
                        <Route path="/mycharacters"><Team from="oauth" /></Route>
                        <Route path="/about" render={this.routeAbout} />
                        <Route path="/privacy"><Privacy /></Route>
                        <Route path="/calculator" render={this.routeCalculator} />
                        <Route path="/minigames"><SolarCollector /></Route>
                        <Route path="/calendar"><Calendar /></Route>
                        <Route path="/brand"><Brand /></Route>
                        <Route path="/tickets"><Tickets /></Route>
                        <Route path="/grants" render={this.routeGrants}></Route>
                        <Route path="/twitch-drops" render={this.routeTwitchDrops}></Route>
                        <Route path="/" render={this.routeRoot} />
                    </Switch>
                </div>
                <Footer />
            </div>
        );
    }

    render() {
        return (
            <ThemeProvider theme={MuiTheme}>
                <Router>
                    <Switch>
                        <Route path="/obs" render={this.routeStream} />
                        <Route render={this.content} />
                    </Switch>
                </Router>
            </ThemeProvider>
        );
    }
}

export default App;