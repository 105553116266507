import React, { Component } from 'react';

import Section from './components/Section';

class Brand extends Component {
    render() {
        document.title = 'Logos | Data for Azeroth | World of Warcraft Leaderboards for Collectors';

        return (
            <Section title="Logos">
                <p>
                    I always appreciate when the community shares <i>Data for Azeroth</i> with others.
                </p>
                <p>
                    If you would like to add a link to <i>Data for Azeroth</i> on your site,
                    here are some logos you can use. I
                    plan to add more variations in the future. Thank you!
                </p>
                <div className="d-flex flex-wrap align-items-baseline justify-content-center">
                    <div className="text-center m-5"><a href="/icon32.png" target="external"><img src="/icon32.png" alt="Logo" /><br/>(32x32 PNG)</a></div>
                    <div className="text-center m-5"><a href="/icon192.png" target="external"><img src="/icon192.png" alt="Logo" /><br/>(192x192 PNG)</a></div>
                    <div className="text-center m-5"><a href="/icon256.png" target="external"><img src="/icon256.png" alt="Logo" /><br/>(256x256 PNG)</a></div>
                    <div className="text-center m-5"><a href="/icon384.png" target="external"><img src="/icon384.png" alt="Logo" /><br/>(384x384 PNG)</a></div>
                    <div className="text-center m-5"><a href="/icon512.png" target="external"><img src="/icon512.png" alt="Logo" /><br/>(512x512 PNG)</a></div>
                </div>
            </Section>
        );
    }
}

export default Brand;