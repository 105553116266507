import React, { Component } from 'react';

import Section from '../components/Section';

class SolarCollector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slot1: this.random(),
            slot2: this.random(),
            slot3: this.random(),
            slot4: this.random(),
            slot5: this.random(),
        };
    }

    random = () => {
        return Math.random() < 0.5;
    }

    toggle1 = (event) => {
        this.setState(state => ({ slot1: !state.slot1, slot2: !state.slot2, slot3: !state.slot3 }));
    }

    toggle2 = (event) => {
        this.setState(state => ({ slot1: !state.slot1, slot2: !state.slot2, slot3: !state.slot3, slot4: !state.slot4 }));
    }

    toggle3 = (event) => {
        this.setState(state => ({ slot1: !state.slot1, slot2: !state.slot2, slot3: !state.slot3, slot4: !state.slot4, slot5: !state.slot5 }));
    }

    toggle4 = (event) => {
        this.setState(state => ({ slot2: !state.slot2, slot3: !state.slot3, slot4: !state.slot4, slot5: !state.slot5 }));
    }

    toggle5 = (event) => {
        this.setState(state => ({ slot3: !state.slot3, slot4: !state.slot4, slot5: !state.slot5 }));
    }

    randomize = (event) => {
        this.setState({
            slot1: this.random(),
            slot2: this.random(),
            slot3: this.random(),
            slot4: this.random(),
            slot5: this.random(),
        });
    }

    render() {
        document.title = 'Solar Collector | Data for Azeroth | World of Warcraft Leaderboards for Collectors';

        return (
            <Section title="Solar Collector" subtitle="Uldum Mini-Game" color="class-11" icon="https://render.worldofwarcraft.com/us/icons/56/inv_helm_suncrown_d_01.jpg">
                Whether you want to practice or just play for fun, here is the <a href="https://www.wowhead.com/quest=57235/solar-collector" target="external">Solar Collector</a> mini-game from Uldum!
                Light up all the triangles to win. I would also recommend the <a href="https://www.curseforge.com/wow/addons/solarcollector" target="external">SolarCollector Addon</a> to help solve the puzzle in game.

                <div className="d-flex mt-5">
                    <div className="mx-auto d-flex solar">
                        <div><button className="btn btn-primary-outline" onClick={this.toggle1}>{this.state.slot1 ? <>&#9660;</> : <>&#9661;</>}</button></div>
                        <div className="mt-3"><button className="btn btn-primary-outline" onClick={this.toggle2}>{this.state.slot2 ? <>&#9650;</> : <>&#9651;</>}</button></div>
                        <div><button className="btn btn-primary-outline" onClick={this.toggle3}>{this.state.slot3 ? <>&#9660;</> : <>&#9661;</>}</button></div>
                        <div className="mt-3"><button className="btn btn-primary-outline" onClick={this.toggle4}>{this.state.slot4 ? <>&#9650;</> : <>&#9651;</>}</button></div>
                        <div><button className="btn btn-primary-outline" onClick={this.toggle5}>{this.state.slot5 ? <>&#9660;</> : <>&#9661;</>}</button></div>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="mx-auto">{this.state.slot1 && this.state.slot2 && this.state.slot3 && this.state.slot4 && this.state.slot5 ? 'Victory!' : <>&nbsp;</>}</div>
                </div>

                <div className="d-flex mt-5">
                    <div className="mx-auto">
                        <button className="btn btn-primary" onClick={this.randomize}>Randomize</button>
                    </div>
                </div>
            </Section>
        );
    }
}

export default SolarCollector;