import React, { Component } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUserCheck, faTimes, faBan, faExclamationTriangle, faMinusCircle, faUser, faCog, faGift, faHatWizard, faStreetView } from '@fortawesome/free-solid-svg-icons';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';

import Tag from './Tag';
import FactionIcon from './FactionIcon';

import moment from 'moment';
import login from '../util/login';
import utils from '../util/utils';

import './TagTooltip.scss';

const RACESMAP = {};
utils.races.forEach(r => RACESMAP[r.key] = r.value);

const CLASSMAP = {};
utils.classes.forEach(r => CLASSMAP[r.key] = r.value);

export default class TagTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collectedBy: null
        };
    }

    componentDidUpdate(previous) {
        if (this.props.item?.field === previous.item?.field && this.props.item?.id === previous.item?.id) return;
        this.setState({ collectedBy: null }); // reset cached tooltip
    }

    standing = (value) => {
        if (!value) return null;

        const tiers = this.props.item?.tiers;
        if (!tiers?.length) return null;

        const tier = tiers.reduce((a, c) => value >= c.min_value && c.min_value >= a.min_value ? c : a, { min_value: -1 });
        if (!tier?.name) return null;

        const completed = tier.min_value === tier.max_value;
        const percent = completed ? 100 : 100 * (value - tier.min_value) / (tier.max_value - tier.min_value);

        return (
            <tr>
                <td colSpan={3} className="pt-0 pb-1 px-1">
                    <div className="progress bg-dark position-relative">
                        <div className="progress-bar bg-success" style={{ width: percent + '%' }} role="progressbar" aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100"></div>
                        <div className="progress-bar bg-transparent position-absolute" style={{ width: '100%', top: 0, bottom: 0, left: 0 }}>
                            {tier.name}
                            {completed ? null : <> {value - tier.min_value} / {tier.max_value - tier.min_value}</>}
                        </div>
                    </div>
                </td>
            </tr>
        );
    }

    minitag = (character) => {
        const src = 'https://render.worldofwarcraft.com/' + (character.thumbnail ? character.region.toLowerCase() + '/character/' + character.thumbnail : 'us/icons/36/inv_misc_questionmark.jpg');
        return (
            <React.Fragment key={src}>
                <tr>
                    <td className="align-middle p-1 align-middle" width="1%">
                        <img className="rounded" style={{width:'2em',height:'2em'}} src={src} alt="" />
                    </td>
                    <td className="align-middle p-1 align-middle">
                        <div className="text-nowrap"><Link to={'/characters/' + encodeURIComponent(character.region) + '/' + encodeURIComponent(character.realm) + '/' + encodeURIComponent(character.name)} className={'class-' + character.class}>{character.name}</Link></div>
                        <div className="text-nowrap small">{character.region?.toUpperCase()}{character.realm ? '-' : ''}{character.realm}</div>
                    </td>
                    <td className="align-middle p-1 align-middle" width="1%">
                        <div className={'badge ' + (character.character ? 'badge-collected' : (character.account ? 'badge-collected' : 'badge-uncollected'))}><FontAwesomeIcon icon={character.character ? faCheck : (character.account ? faUserCheck : faTimes)} /></div>
                    </td>
                </tr>
                {this.standing(character.standing)}
            </React.Fragment>
        );
    }

    collectedBy = () => {
        const json = this.state.collectedBy;
        if (!json) return null;
        if (json.loading) return <FontAwesomeIcon icon={faCog} spin />;
        if (json.error) return 'Error contacting server';

        const alts = json.login.map(c => this.minitag(c));
        const favs = json.favorites.map(c => this.minitag(c));
        if (!alts.length && !favs.length) return 'No character info';

        return (
            <>
                {alts.length ?
                    <div>
                        <div>My Characters</div>
                        <table className="m-0"><tbody>{alts}</tbody></table>
                    </div>
                : null}
                {favs.length ?
                    <div>
                        <div>Favorites</div>
                        <table className="m-0"><tbody>{favs}</tbody></table>
                    </div>
                : null}
            </>
        );
    }

    shared = (item) => {
        if (!item?.shared?.length) return null;

        return (
            <div>
                {item.shared.length} shared appearances
                {item.shared.map((item, idx) => <Tag key={idx} item={item} size="shared" showCollected={false} showDetails={false} showRarity={false} />)}
            </div>
        );
    }

    open = async () => {
        if (!this.props.onCollectedBy) return; // no data
        if (this.state.collectedBy) return; // already loaded

        this.setState({ collectedBy: { loading: true } });

        try {
            const json = await this.props.onCollectedBy(this.props.item.field, this.props.item.id);
            this.setState({ collectedBy: json });
        } catch (e) {
            this.setState({ collectedBy: { error: true } });
        }
    }

    render() {
        const item = this.props.item;

        const shared = this.shared(item);
        const collectedBy = this.collectedBy();

        const title = (
            <>
                <div className="card-header p-2 text-center">
                    {item.count ? item.count + ' x ' : null}
                    {item.name}
                    {item.prof && item.field !== 'quests' ? <div className="small">{item.prof}</div> : null}
                    {item.timestamp ? <div className="small">{moment.utc(item.timestamp).local().format('ll LTS')}</div> : null}
                    {item.sets ? item.sets.map((set, idx) => <div className="small" key={idx}>{set.name}{set.description ? ' (' + set.description + ')' : ''}<br/>{set.group}</div>) : null}
                </div>
                <div className="card-body p-1 pr-3 tag-tooltip-body">
                    <div><span className="badge badge-align"><FontAwesomeIcon icon={faUser} /></span>{(typeof item.rarity === 'number') ? <>Collected by {utils.toFixed(item.rarity, 4)}% of accounts</> : 'No rarity info'}</div>
                    {item.faction < 2 ? <div className="badge-align"><FactionIcon faction={item.faction} />{item.faction === 0 ? 'Alliance Only' : 'Horde Only'}</div> : null}
                    {item.race ? <div className="d-flex"><div><span className="badge badge-align"><FontAwesomeIcon icon={faStreetView} /></span></div><div>{item.race.map(id => RACESMAP[id]).join(', ')}</div></div> : null}
                    {item.class ? <div className="d-flex"><div><span className="badge badge-align"><FontAwesomeIcon icon={faHatWizard} /></span></div><div>{item.class.map(id => CLASSMAP[id]).join(', ')}</div></div> : null}
                    {item.granted ? <div className="d-flex"><div><span className="badge badge-align badge-collected"><FontAwesomeIcon icon={faGift} /></span></div><div>Blizzard did not give credit for this item, it was granted by Data for Azeroth</div></div> : null}
                    {item.unobtainable ? <div><span className={'badge badge-align badge-un-' + item.unobtainable}><FontAwesomeIcon icon={faBan} /></span>{utils.unobtainables.find(o => o.key === item.unobtainable)?.value || 'Unobtainable'}</div> : null}
                    {item.missing ? <div className="d-flex"><div><span className="badge badge-align badge-1"><FontAwesomeIcon icon={faExclamationTriangle} /></span></div><div>This item is missing from Blizzard's API and cannot be tracked by Data for Azeroth</div></div> : null}
                    {item.excluded ? <div className="d-flex"><div><span className="badge badge-align badge-1"><FontAwesomeIcon icon={faMinusCircle} /></span></div><div>This item is excluded from rankings</div></div> : null}
                    {item.note ? <div className="d-flex"><div><span className="badge badge-align badge-primary"><FontAwesomeIcon icon={faCommentDots} /></span></div><div><em>{item.note}</em></div></div> : null}

                    {shared || collectedBy ? <div className="d-flex mt-3">{shared}{collectedBy}</div> : null}
                </div>
                {this.props.showDetails ? <div className="card-footer p-2 text-center"><Link to={'/collections/' + item.field + '/' + item.id} target="_blank">More...</Link></div> : null}
            </>
        );


        const content = <Tooltip title={title} placement="right" classes={{ tooltip: 'card m-0 p-0 tag-tooltip' }} enterDelay={500} enterNextDelay={500} onOpen={this.open}>{this.props.children}</Tooltip>;
        if (this.props.showDetails && login.contributor()) {
            return <Link to={'/collections/' + item.field + '/' + item.id} target="_blank">{content}</Link>;
        }
        return content;
    }
}