import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';

export default class ToggleButton extends Component {
    render() {
        return (
            <button type="button" className={'btn btn-primary text-nowrap' + (this.props.checked ? ' active' : '')} onClick={this.props.onToggle}>
                <FontAwesomeIcon icon={this.props.checked ? faCheckCircle : faCircle} /> {this.props.label}
            </button>
        );
    }
}