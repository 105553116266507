import { createRoot } from 'react-dom/client';
import App from './App';

if (window.location.hostname === 'dataforazeroth.com') {
    window.location = window.location.href.replace('//dataforazeroth.com', '//www.dataforazeroth.com');
} else if (window.location.hostname === 'localhost') {
    window.location = window.location.href.replace('//localhost', '//dev.dataforazeroth.com');
} else {
    const root = createRoot(document.getElementById('root'));
    root.render(<App />);
}
