import React, { Component } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';

import Realm from './Realm';
import FavoriteDownButton from '../favorites/FavoriteDownButton';

import api from '../util/api';

class CharacterLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            realm: '',
            realms: new Set(),
            showCompare: false
        };
    }

    componentDidMount() {
        this.loadRealms();
        this.action(this.props.history.location);
        this.props.history.listen((location, action) => {
            this.action(location);
        });
    }

    async loadRealms() {
        const json = await api.web('realms');
        const realms = json.realms.map(realm => realm.region.toUpperCase() + '-' + realm.name);
        this.setState({ realms: new Set(realms) });
    }

    action(location) {
        if (location.pathname.startsWith('/characters/')) {
            this.setState({ showCompare: true });
        } else {
            this.setState({ showCompare: false });
        }
    }

    setRealm = (realm) => {
        this.setState({ realm: realm ? realm.trim() : '' });
    }

    getInput() {
        if (this.state.realm === '') {
            alert('Please select a realm');
            return null;
        }

        if (!this.state.realms.has(this.state.realm)) {
            alert('Invalid realm name');
            return null;
        }

        const character = document.getElementById('character').value.trim();
        if (character === '') {
            alert('Please type a character name');
            return null;
        }

        if (/[-\d\s]/.test(character)) {
            alert('Invalid character name');
            return null;
        }

        const idx = this.state.realm.indexOf('-');
        const region = this.state.realm.substring(0, idx);
        const realm = this.state.realm.substring(idx + 1);

        document.getElementById('character').value = '';

        return { region, realm, character };
    }

    handleSubmit = (event) => {
        if (event) event.preventDefault(); // prevent form submission

        const input = this.getInput();
        if (input) {
            this.props.history.push('/characters/' + encodeURIComponent(input.region) + '/' + encodeURIComponent(input.realm) + '/' + encodeURIComponent(input.character));
        }

        return false;
    }

    handleCompare = (event) => {
        if (event) event.preventDefault(); // prevent form submission

        const input = this.getInput();
        if (input) {
            const en = encodeURIComponent;
            const parts = document.location.pathname.split('/');
            this.props.history.push('/characters/' + parts[2] + '/' + parts[3] + '/' + parts[4] + '/compare/' + en(input.region) + '/' + en(input.realm) + '/' + en(input.character));
        }

        return false;
    }

    hideAutocomplete() {
        document.getElementById('character').focus(); // this makes sure realm autocomplete closes
        document.getElementById('character').blur(); // this makes sure character autocomplete closes
    }

    render() {
        return (
            <form className="form-inline" onSubmit={this.handleSubmit}>
                <div className="input-group w-100">
                    <Realm onChange={this.setRealm} />
                    <input type="text" id="character" className="form-control" placeholder="Character Name" />
                    <div className="input-group-append">
                        <FavoriteDownButton />
                        {!this.state.showCompare || !window.bootstrap.md.matches ? null : <Tooltip title="Add Character for Comparison"><button type="button" className="btn btn-primary" onClick={this.handleCompare} onMouseEnter={this.hideAutocomplete}><FontAwesomeIcon icon={faPlus} /></button></Tooltip>}
                        <Tooltip title="Lookup Character"><button type="submit" className="btn btn-primary" onMouseEnter={this.hideAutocomplete}><FontAwesomeIcon icon={faSearch} /></button></Tooltip>
                    </div>
                </div>
            </form>
        );
    }
}

export default CharacterLookup;
