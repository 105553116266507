const contributors = new Set();

// DFA
contributors.add(51770063); // us#drenden#shoogen
contributors.add(407943682); // eu#silvermoon#maylesa
contributors.add(50062721); // us#bloodscalp#gaulish
contributors.add(75752090); // us#frostmourne#kelly
contributors.add(1043357); // us#hyjal#anshlun
contributors.add(349794990); // us#dalaran#turkeycat
contributors.add(886828659); // us#eredar#quester
contributors.add(100300326); // eu#aegwynn#gold

// ATT
contributors.add(72350406); // us#area-52#jezartroz
contributors.add(103013517); // eu#earthen-ring#myrhial

function token() {
    const token = localStorage.getItem('token.blizzard');
    if (!token) return {};
    return JSON.parse(token);
}

// determines if the currently logged user is a contributor
function contributor() {
    return contributors.has(token().id);
}

function account() {
    return token().account;
}

function id() {
    return token().id;
}

function tier() {
    return token().tier;
}

const login = { contributor, account, id, tier, token };
export default login;