import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSync, faUser, faUserNinja, faGavel, faCircle, faCheckCircle, faChevronUp, faChevronDown, faChevronCircleUp, faChevronCircleDown, faArrowAltCircleUp, faArrowAltCircleDown, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar, faUser as farUser } from '@fortawesome/free-regular-svg-icons';

import FavoriteButton from '../components/FavoriteButton';
import NumberInput from '../components/NumberInput';
import Rewards from '../login/Rewards';
import Section from '../components/Section';
import CharactersError from './CharactersError';
import Guilds from './Guilds';
import MoveButton from './MoveButton';
import RefreshButton from '../components/RefreshButton';
import MainButton from './MainButton';
import HideButton from './HideButton';
import ToggleButtonWrapper from '../collections/ToggleButtonWrapper';

import favorite from '../util/favorite';
import api from '../util/api';
import utils from '../util/utils';

import './Team.scss';

const accountName = (id) => {
    const rename = localStorage.getItem('rename.' + id);
    return rename || ('Account #' + (id || 'Unknown'));
}

// these drop-downs use slightly different ids/names for Unknown
const RACES = utils.races.map(r => r.key === '' ? { key: undefined, value: 'Unknown' } : r);
const CLASSES = utils.classes.map(c => c.key === '' ? { key: undefined, value: 'Unknown' } : c);

const PROFESSIONS = {};
utils.professions.forEach(p => PROFESSIONS[p.toLowerCase()] = p);
const PRIMARIES = { ...PROFESSIONS };
[ 'archaeology', 'cooking', 'fishing', 'gnomish engineering', 'goblin engineering' ].forEach(p => delete PRIMARIES[p]);
const PROFFILTERS = [ { key: undefined, value: 'Unknown' }, ...Object.keys(PRIMARIES).map(key => ({ key, value: PRIMARIES[key] })) ];

const RACESMAP = {};
utils.races.forEach(r => RACESMAP[r.key] = r.value);

const CHARSORTS = {
    'realm-asc': (a, b) => utils.collator.compare(a.realm, b.realm),
    'realm-desc': (a, b) => utils.collator.compare(b.realm, a.realm),
    'name-asc': (a, b) => utils.collator.compare(a.character, b.character),
    'name-desc': (a, b) => utils.collator.compare(b.character, a.character),
    'guild-asc': (a, b) => utils.collator.compare(a.details?.character?.guildName || '', b.details?.character?.guildName || ''),
    'guild-desc': (a, b) => utils.collator.compare(b.details?.character?.guildName || '', a.details?.character?.guildName || ''),
    'level-asc': (a, b) => 10000 * (a.details?.character?.level || 0) + (a.details?.character?.averageItemLevel || 0) - 10000 * (b.details?.character?.level || 0) - (b.details?.character?.averageItemLevel || 0),
    'level-desc': (a, b) => 10000 * (b.details?.character?.level || 0) +  (b.details?.character?.averageItemLevel || 0) - 10000 * (a.details?.character?.level || 0) - (a.details?.character?.averageItemLevel || 0),
    'wowaccount-asc': (a, b) => utils.collator.compare(accountName(a?.details?.wowaccount), accountName(b?.details?.wowaccount)),
    'wowaccount-desc': (a, b) => utils.collator.compare(accountName(b?.details?.wowaccount), accountName(a?.details?.wowaccount))
};

const GUILDSORTS = {
    'realm-asc': (a, b) => utils.collator.compare(a.realm, b.realm),
    'realm-desc': (a, b) => utils.collator.compare(b.realm, a.realm),
    'name-asc': (a, b) => utils.collator.compare(a.name, b.name),
    'name-desc': (a, b) => utils.collator.compare(b.name, a.name)
};

class Team extends Component {
    constructor(props) {
        super(props);
        this.blankstate = {
            loadingAll: false,
            loadingOne: false,
            loadingExclusive: false,
            error: false,
            favorites: null,
            favkey: 'favorites',
            wowaccounts: null,
            filter: '',
            levelMin: '',
            levelMax: '',
            itemLevelMin: '',
            itemLevelMax: '',
            regex: null,
            sort: localStorage.favsort || 'manual',
            main: 0,
            character: null,
            account: null,
            details: null,
            exclusive: null,
            showActive: localStorage.showActive !== 'false',
            showNotFound: localStorage.showNotFound !== 'false',
            showHidden: false, // localStorage.showHidden === 'true',
            showFactions: false,
            showRaces: false,
            showClasses: false,
            showProfessions: false,
            showLevels: false,
            showItemLevels: false,
            showAccounts: false,
            showSort: false,
            hideFactions: new Set(),
            hideRaces: new Set(),
            hideClasses: new Set(),
            hideProfessions: new Set(),
            hideAccounts: new Set(),
            sortedCharacters: [],
            filteredCharacters: [],
            sortedGuilds: [],
            filteredGuilds: []
        };
        this.state = { ...this.blankstate };
    }

    componentDidMount() {
        this.loadAll();
    }

    componentDidUpdate(previous) {
        if (this.props.from === previous.from) return;
        this.loadAll();
    }

    toggleActive = () => {
        localStorage.setItem('showActive', this.state.showActive ? 'false' : 'true');
        this.setState({ showActive: !this.state.showActive });
        this.updateLists();
    }

    toggleNotFound = () => {
        localStorage.setItem('showNotFound', this.state.showNotFound ? 'false' : 'true');
        this.setState({ showNotFound: !this.state.showNotFound });
        this.updateLists();
    }

    toggleHidden = () => {
        localStorage.setItem('showHidden', this.state.showHidden ? 'false' : 'true');
        this.setState({ showHidden: !this.state.showHidden });
        this.updateLists();
    }

    toggleFactions = () => {
        this.setState({ showFactions: !this.state.showFactions });
    }

    toggleRaces = () => {
        this.setState({ showRaces: !this.state.showRaces });
    }

    toggleClasses = () => {
        this.setState({ showClasses: !this.state.showClasses });
    }

    toggleProfessions = () => {
        this.setState({ showProfessions: !this.state.showProfessions });
    }

    toggleLevels = () => {
        this.setState({ showLevels: !this.state.showLevels });
    }

    toggleItemLevels = () => {
        this.setState({ showItemLevels: !this.state.showItemLevels });
    }

    toggleAccounts = () => {
        this.setState({ showAccounts: !this.state.showAccounts });
    }

    toggleSort = () => {
        this.setState({ showSort: !this.state.showSort });
    }

    toggleFilter = (property, arg1, arg2) => {
        const hide = new Set(this.state[property]);
        if (arg1 === 'All') {
            for (let p of arg2) {
                hide.delete(p);
            }
        } else if (arg1 === 'None') {
            for (let p of arg2) {
                hide.add(p);
            }
        } else {
            for (let s of Array.isArray(arg1) ? arg1 : [ arg1 ]) {
                hide.has(s) ? hide.delete(s) : hide.add(s);
            }
        }
        this.setState({ [property]: hide });
        this.updateLists();
    }

    sort = (event) => {
        let sort = event.currentTarget.getAttribute('data-sort');

        if (sort === 'level') {
            sort = this.state.sort === sort + '-desc' ? sort + '-asc' : sort + '-desc';
        } else if (sort !== 'manual') {
            sort = this.state.sort === sort + '-asc' ? sort + '-desc' : sort + '-asc';
        }

        localStorage.setItem('favsort', sort);
        this.setState({ sort });
        this.updateLists();
    }

    async loadUser(token) {
        // validate user token
        if (!token?.region) {
            this.setState({ error: true, details: 'Invalid token\nPOST Info\n' + localStorage.getItem('token.blizzard') });
            return false;
        }

        // Blizzard API call
        const response = await api.post('user/' + encodeURIComponent(token.region), token);
        if (response.status !== 200) {
            this.setState({ error: true, details: 'HTTP Error ' + response.status + '\nPOST Info\n' + localStorage.getItem('token.blizzard') });
            return false;
        }

        const json = await response.json();
        if (!json?.characters?.length) {
            this.setState({ error: true, details: 'Invalid JSON Response\nPOST Info\n' + localStorage.getItem('token.blizzard') });
            return false;
        }

        // load stubs from the database
        const response2 = await api.post('stubs', json.characters);
        if (response2.status !== 200) {
            this.setState({ error: true, details: 'HTTP Error ' + response2.status + '\nPOST Info\n' + localStorage.getItem('token.blizzard') });
            return false;
        }

        const json2 = await response2.json();
        if (!Array.isArray(json2)) {
            this.setState({ error: true, details: 'Invalid JSON Response\nPOST Info\n' + localStorage.getItem('token.blizzard') });
            return false;
        }

        return {
            id: json.id,
            api: json.characters.map(c => ({ character: { ...c, name: c.character }})),
            characters: json2.filter(c => c?.character?.key)
        };
    }

    async loadAll() {
        // reset state first
        this.setState(this.blankstate);

        let favorites = null;
        let favoritesJson = null;

        if (this.props.from === 'favorites') {
            favorites = favorite.get('favorites').getFavorites();
            const response = await api.post('stubs', favorites.map(c => ({ region: c.region, realm: c.realm, character: c.character })));
            if (response.status !== 200) {
                this.setState({ error: true });
                return;
            }

            favoritesJson = await response.json();
            this.setState({ favkey: 'favorites' });
        } else if (this.props.from === 'oauth') {
            let token = null;
            try {
                token = JSON.parse(localStorage.getItem('token.blizzard'));
            } catch (e) {

            }

            const json = await this.loadUser(token);
            if (!json) return; // error message already set

            // update login info
            const main = (json.characters[0] || json.api[0]);
            token.id = json.id;
            token.character = utils.clean(main.character?.region) + '#' + utils.slug(main.character?.realm) + '#' + utils.clean(main.character?.name);
            token.account = main.account?.id || undefined;
            token.tier = main.account?.tier || undefined;
            token.contributor = main.account?.contributor || undefined;
            localStorage.setItem('token.blizzard', JSON.stringify(token));

            // add any missing characters to the end
            const login = favorite.get('favorites.login' + token.id, 99999);
            const found = new Set();
            for (let stub of [ ...json.characters, ...json.api ]) {
                found.add(stub.character.region + '#' + stub.character.realm + '#' + stub.character.name);
                if (login.isFavorite(stub.character.region, stub.character.realm, stub.character.name)) {
                    login.updateFavorite(stub.character.region, stub.character.realm, stub.character.name, stub.character.class);
                } else {
                    login.addFavorite(stub.character.region, stub.character.realm, stub.character.name, stub.character.class);
                }
            }

            for (let fav of login.getFavorites()) {
                if (!found.has(fav.region + '#' + fav.realm + '#' + fav.character)) {
                    login.removeFavorite(fav.region, fav.realm, fav.character);
                }
            }

            this.setState({ favkey: 'favorites.login' + token.id, character: token.character, account: token.account });
            favorites = login.getFavorites();

            // copy wowaccount from the original API call into the character's stub data
            favoritesJson = json.characters.map(c => ({ ...c, wowaccount: json?.api?.find(api => api?.character?.key === c?.character?.key)?.character?.wowaccount }));
        }

        // after different HTTP calls are done, load it into state
        if (favorites !== null) {
            const wowaccounts = new Set();
            const state = { favorites, main: 0 };

            for (let character of favorites) {
                const key = utils.clean(character.region) + '#' + utils.slug(character.realm) + '#' + utils.clean(character.character);
                const child = favoritesJson.find(j => j.character?.key === key);
                state[character.region + '#' + character.realm + '#' + character.character + '#json'] = child || { message: 'Character requires update' };

                state.main = Math.max(state.main, child?.character?.main || 0);
                if (child?.wowaccount) wowaccounts.add(child?.wowaccount);
            }

            state.wowaccounts = Array.from(wowaccounts);
            state.wowaccounts.sort((a, b) => utils.collator.compare(accountName(a), accountName(b)));

            this.setState(state);
            this.updateLists();
        }
    }

    load = async (region, realm, character, refresh) => {
        const response = await api.post('stubs', [{ region, realm, character }]);
        if (response.status !== 200) return;

        const json = await response.json();
        const key = region + '#' + realm + '#' + character + '#json';
        const value = json[0] || { message: 'Character requires update' };

        if (this.state[key]?.character?.updated === value.character?.updated) {
            // refresh failed
            if (refresh) value.updatefailed = true;
        }

        if (this.state[key]?.wowaccount) {
            // carry over wowaccount
            value.wowaccount = this.state[key].wowaccount;
        }

        this.setState(state => ({ [key]: value, main: Math.max(state.main, value?.character?.main || 0) }));
        this.updateLists();
    }

    changeFilter = (event) => {
        const filter = event.currentTarget.value;
        const regex = utils.regex(filter);
        this.setState({ filter, regex });
        this.updateLists();
    }

    changeLevelMin = (levelMin) => {
        this.setState({ levelMin });
        this.updateLists();
    }

    changeLevelMax = (levelMax) => {
        this.setState({ levelMax });
        this.updateLists();
    }

    changeItemLevelMin = (itemLevelMin) => {
        this.setState({ itemLevelMin });
        this.updateLists();
    }

    changeItemLevelMax = (itemLevelMax) => {
        this.setState({ itemLevelMax });
        this.updateLists();
    }

    // updates various derived state based on character list, including filtering and sorting
    updateLists = () => {
        this.setState(state => {
            if (!state.favorites) return null; // still loading

            // sort the list of characters
            const sortedCharacters = state.favorites.map((c, idx) => {
                const details = state[c.region + '#' + c.realm + '#' + c.character + '#json'];
                const fulltext = (c.region || '') + '-' + (c.realm || '') + '-' + (c.character || '') + ' ' + (details?.character?.guildName || '');
                return { ...c, idx, details, fulltext };
            });

            if (CHARSORTS[state.sort]) sortedCharacters.sort(CHARSORTS[state.sort]);

            // then filter the list of characters
            const filteredCharacters = sortedCharacters.filter(c => {
                if (state.regex) {
                    if (!c.fulltext?.match(state.regex)) return false;
                }

                if (state.levelMin) {
                    if (!c.details?.character?.level) return false;

                    const min = parseInt(state.levelMin);
                    if (c.details.character.level < min) return false;
                }

                if (state.levelMax) {
                    if (!c.details?.character?.level) return false;

                    const max = parseInt(state.levelMax);
                    if (c.details.character.level > max) return false;
                }

                if (state.itemLevelMin) {
                    if (!c.details?.character?.averageItemLevel) return false;

                    const min = parseInt(state.itemLevelMin);
                    if (c.details.character.averageItemLevel < min) return false;
                }

                if (state.itemLevelMax) {
                    if (!c.details?.character?.averageItemLevel) return false;

                    const max = parseInt(state.itemLevelMax);
                    if (c.details.character.averageItemLevel > max) return false;
                }

                if (state.hideFactions.has(c.details?.character?.faction)) return false;
                if (state.hideRaces.has(c.details?.character?.race)) return false;
                if (state.hideClasses.has(c.details?.character?.class)) return false;
                if (state.hideAccounts.has(c.details?.wowaccount || 0)) return false;

                // only hide if BOTH professions are hidden, but be careful with characters that don't have 2
                const professions = Object.keys(c.details?.character?.professions || {}).filter(p => PRIMARIES[p]);
                if (state.hideProfessions.has(professions[0]) && state.hideProfessions.has(professions[1] || professions[0])) return false;

                if (!state.showActive) {
                    // active = not hidden, has details, does not have an error message
                    if (!c.hidden && c.details && !c.details.message) return false;
                }

                if (!state.showNotFound) {
                    // missing = not hidden, no details OR error message
                    if (!c.hidden && (!c.details || c.details.message)) return false;
                }

                if (!state.showHidden) {
                    if (c.hidden) return false;
                }

                return true;
            });

            // gather a list of unique guilds
            const parseGuilds = () => {
                const guilds = {};
                for (let c of state.favorites) {
                    const json = state[c.region + '#' + c.realm + '#' + c.character + '#json'];
                    if (!json?.character?.guildName) continue;

                    const guild = {
                        region: json.character.region,
                        realm: json.character.guildRealm,
                        name: json.character.guildName
                    };
                    guild.fulltext = guild.realm + '-' + guild.name;
                    guild.key = utils.clean(guild.region) + '#' + utils.slug(guild.realm) + '#' + utils.slug(guild.name); // used by <Rewards />

                    guilds[guild.region + '#' + guild.realm + '#' + guild.name] = guild;
                }
                return Object.values(guilds);
            }

            // sort the list of guilds
            const sortedGuilds = parseGuilds();
            sortedGuilds.sort(GUILDSORTS[state.sort] || GUILDSORTS['name-asc']);

            // then filter the list of guilds
            const filteredGuilds = sortedGuilds.filter(g => {
                if (state.regex) {
                    if (!g.fulltext?.match(state.regex)) return false;
                }

                return true;
            });

            return { sortedCharacters, filteredCharacters, sortedGuilds, filteredGuilds };
        });
    }

    reorder = (favorites) => {
        this.setState({ favorites });
        this.updateLists();
    }

    refreshAll = async (event) => {
        if (event) event.preventDefault(); // prevent form submission
        if (this.state.loadingAll) return;

        this.setState({ loadingAll: true });

        try {
            const characters = [ ...this.state.filteredCharacters ];
            const response = await api.post('queue/add', characters);
            const tokens = await response.json();

            // indicates that characters have been safely added to queue
            if (tokens.length) this.setState({ loadingAll: tokens.length });

            for (let i = 0; i < characters.length; i++) {
                const character = characters[i];
                this.setState({ [character.region + '#' + character.realm + '#' + character.character]: true });

                try {
                    await this.queueWait(character.region, character.realm, character.character, tokens[i]);
                    await this.load(character.region, character.realm, character.character, true);
                } catch (e) {

                }

                this.setState({ [character.region + '#' + character.realm + '#' + character.character]: false });
            }
        } catch (e) {

        }

        this.setState({ loadingAll: false });
        return false;
    }

    queueWait = async (region, realm, name, token) => {
        let position = 0;
        while (true) {
            await utils.sleep(position ? 15000 : 1000);

            const response = await api.get('queue/status/' + encodeURIComponent(token));
            const json = await response.json();
            if (json.status === 'DONE') return true;

            position = json.position || 1;
            this.setState({ [region + '#' + realm + '#' + name]: json.position || true });
        }
    }

    lockout = () => {
        this.setState({ loadingOne: true });
    }

    refresh = async (region, realm, character) => {
        if (region && realm && character) await this.load(region, realm, character, true);
        this.setState({ loadingOne: false });
    }

    main = async (region, realm, character) => {
        const token = JSON.parse(localStorage.getItem('token.blizzard'));
        const endpoint = 'main/' + encodeURIComponent(region) + '/' + encodeURIComponent(realm) + '/' + encodeURIComponent(character) + '?token=' + encodeURIComponent(token.region) + encodeURIComponent(token.access_token);
        const response = await api.post(endpoint, { main: true });
        if (response.status !== 204) {
            const key = region + '#' + realm + '#' + character + '#json';
            this.setState(state => ({ [key]: { ...state[key], updatefailed: true } }));
            return;
        }

        await this.load(region, realm, character, false);
    }

    hide = (region, realm, character, hidden) => {
        const store = favorite.get(this.state.favkey);
        store.updateFavorite(region, realm, character, null, null, hidden);
        this.setState({ favorites: store.getFavorites() });
        this.updateLists();
    }

    exclusive = async (event) => {
        if (event) event.preventDefault(); // prevent form submission
        if (this.state.loadingExclusive) return;

        const parts = this.state.character?.split('#');
        if (parts?.length !== 3) return; // TODO should give an error message

        this.setState({ loadingExclusive: true });

        try {
            const endpoint = 'exclusive/' + encodeURIComponent(parts[0]) + '/' + encodeURIComponent(parts[1]) + '/' + encodeURIComponent(parts[2]);
            const response = await api.get(endpoint);
            if (response.status !== 200) return; // TODO should give an error message

            const json = await response.json();
            this.setState({ exclusive: json });
        } finally {
            this.setState({ loadingExclusive: false });
        }
    }

    accountButton = (id) => {
        return <Tooltip title={<>Rename account #{id}</>}><span style={{ cursor: 'pointer' }} data-account={id} onClick={this.accountButtonClick}>{accountName(id)}</span></Tooltip>;
    }

    accountButtonClick = (event) => {
        const id = event.currentTarget.getAttribute('data-account');
        if (!id) return; // shouldn't happen

        const rename = prompt('Rename account:');
        if (!rename && rename !== '') return; // cancel

        if (rename === '') {
            localStorage.removeItem('rename.' + id);
        } else {
            localStorage.setItem('rename.' + id, rename);
        }

        this.setState({ renamed: Date.now() }); // trigger an update
        this.updateLists();
    }

    render() {
        const title = this.props.from === 'oauth' ? 'My Characters' : 'Favorite Characters';
        document.title = title + ' | Data for Azeroth | World of Warcraft Leaderboards for Collectors';

        const table = [];
        const leaderboards = [];

        let count = 0;
        let portrait = null;

        const cols = 4;
        const width = window.bootstrap.md.matches ? (94 / cols) + '%' : '96%';

        if (this.state.error) {
            return <CharactersError details={this.state.details} />;
        } else if (this.state.favorites === null) {
            table.push(
                <tr key="row0">
                    <td colSpan={7} className="text-center"><FontAwesomeIcon icon={faCog} spin /></td>
                </tr>
            );
        } else if (this.state.favorites.length === 0) {
            table.push(
                <tr key="row0">
                    <td colSpan={7}>Please choose your favorite characters by clicking the <FontAwesomeIcon icon={farStar} /> button on their profile.</td>
                </tr>
            );
        } else {
            const temp = this.state.favorites[0];
            portrait = this.state[temp.region + '#' + temp.realm + '#' + temp.character + '#json']?.character;

            const filtered = this.state.filteredCharacters;
            count = filtered.length;

            for (let i = 0; i < filtered.length; i++) {
                const character = filtered[i];
                const loading = this.state[character.region + '#' + character.realm + '#' + character.character];
                const json = this.state[character.region + '#' + character.realm + '#' + character.character + '#json'];
                leaderboards.push(utils.clean(character.region) + '#' + utils.slug(character.realm) + '#' + utils.clean(character.character));

                const showUp = (this.state.sort === 'manual') && (i > 0);
                const showDown = (this.state.sort === 'manual') && (i < filtered.length - 1);

                const refresh = {
                    message: json?.message,
                    timestamp: json?.character?.updated
                };
                const main = {
                    icon: farUser,
                    tooltip: 'Set as main character'
                };

                if (json?.account?.exclude) {
                    main.disabled = true;
                    main.icon = faUserNinja;
                    main.tooltip = 'This character appears to have account-wide achievements disabled';
                    main.warning = main.tooltip;
                } else if (json?.message) {
                    main.disabled = true;
                    main.icon = faUserNinja;
                    main.tooltip = json.message;
                } else if ((this.state.main > 0) && (json?.character?.main === this.state.main)) {
                    main.disabled = true;
                    main.icon = faUser;
                    main.tooltip = 'Main character';
                    if (this.props.from === 'oauth') portrait = json.character;
                }

                if (json?.character && this.props.from === 'oauth' && this.state.account && this.state.account !== json.character.account) {
                    refresh.warning = main.warning || 'Not detected as an alt based on achievements';
                }

                if (typeof loading === 'number') {
                    refresh.message = '#' + loading + ' in queue';
                } else if (json.updatefailed) {
                    refresh.message = 'Update Failed';
                }

                if (this.state.exclusive?.[json?.character?.key]) {
                    refresh.exclusive = `This character appears to have collection items that others don't. Use the "Exclusive to This Character" advanced filter on the collection tabs for more details.`;
                }

                const buttons =
                    <div className={'d-flex justify-content-end' + (window.bootstrap.md.matches ? '' : ' mt-3 mb-2 small')}>
                        <div><RefreshButton loading={loading} waiting={this.state.loadingOne || this.state.loadingAll} region={character.region} realm={character.realm} character={character.character} message={refresh.message} warning={refresh.warning} exclusive={refresh.exclusive} timestamp={refresh.timestamp} onClick={this.lockout} onRefresh={this.refresh} /></div>
                        {this.props.from === 'oauth' ? <div className="pl-1"><MainButton disabled={main.disabled} tooltip={main.tooltip} icon={main.icon} region={character.region} realm={character.realm} character={character.character} onClick={this.main} /></div> : null}
                        {this.props.from === 'oauth' ? <div className="pl-1"><HideButton region={character.region} realm={character.realm} character={character.character} hidden={character.hidden} onClick={this.hide} /></div> : null}
                        <div className="pl-1"><FavoriteButton region={character.region} realm={character.realm} name={character.character} class={json?.character?.class} thumbnail={json?.character?.thumbnail} /></div>
                    </div>;

                const jcp = Object.keys(json?.character?.professions || {}).map(key => ({ key, name: PROFESSIONS[key], primary: PRIMARIES[key], ...json.character.professions[key] }));
                jcp.sort((a, b) => utils.collator.compare(!a.primary + a.name, !b.primary + b.name));

                const professions =
                    <Tooltip title={<table><tbody>{jcp.map(p => <tr key={p.key}><td>{p.name} {p.specialization ? '(' + p.specialization + ')' : null}</td><td className="pl-2 text-right">{p.skill}</td></tr>)}</tbody></table>}>
                        <div>
                            {jcp.filter(p => p.primary).map(p => <div key={p.key} className={'small' + (window.bootstrap.md.matches ? '' : ' profession')}>{p.name}</div>)}
                        </div>
                    </Tooltip>;

                table.push(
                    <tr key={'row' + character.idx}>
                        <td className="align-middle p-0">
                            {showUp ? <MoveButton direction="up" idx={character.idx} to={filtered[i-1].idx} toMax={0} favkey={this.state.favkey} onChange={this.reorder} /> : null}
                            {showDown ? <MoveButton direction="down" idx={character.idx} to={filtered[i+1].idx} toMax={this.state.favorites.length - 1} favkey={this.state.favkey} onChange={this.reorder} /> : null}
                        </td>
                        <td className="align-middle p-0">{json?.character?.thumbnail ? <img className="rounded" style={{width:'2em',height:'2em'}} src={'https://render.worldofwarcraft.com/' + json.character.region.toLowerCase() + '/character/' + json.character.thumbnail} alt="Avatar" /> : null}</td>
                        <td className="align-middle py-1">
                            <div><Link className={'text-break class-' + character.class} to={'/characters/' + encodeURIComponent(character.region) + '/' + encodeURIComponent(character.realm) + '/' + encodeURIComponent(character.character)}>{character.character}</Link></div>
                            {json?.character ?
                                <div className="small">
                                    Level {json.character.level} {RACESMAP[json.character.race]} | <Tooltip title={'Item Level ' + json.character.averageItemLevel}><span><FontAwesomeIcon icon={faGavel} /> {json.character.averageItemLevel}</span></Tooltip>
                                </div>
                            : null}
                            {window.bootstrap.md.matches ? null : <>
                                <div>{professions}</div>
                                <div className="small">{character.region.toUpperCase()}-{character.realm}{this.state.wowaccounts?.length > 1 ? <> ({this.accountButton(json.wowaccount)})</> : null}</div>
                                {buttons}
                            </>}
                        </td>
                        {window.bootstrap.md.matches ? <>
                            <td className="align-middle">{professions}</td>
                            <td className="align-middle text-break">{json?.character?.guildName}</td>
                            <td className="align-middle">{character.region.toUpperCase()}-{character.realm}{this.state.wowaccounts?.length > 1 ? <div className="small">{this.accountButton(json.wowaccount)}</div> : null}</td>
                            <td className="align-middle">{buttons}</td>
                        </> : null}
                    </tr>
                );
            }

            if (table.length === 0) {
                table.push(
                    <tr key="notfound">
                        <td colSpan={2}></td>
                        <td colSpan={window.bootstrap.md.matches ? cols + 1 : 1}>No matches found</td>
                    </tr>
                );
            }
        }

        return (
            <div>
                {this.props.from === 'oauth' && this.state.character ? <Rewards character={this.state.character} guilds={this.state.sortedGuilds} /> : null}

                <Section title={title} subtitle={count + ' Character' + (count === 1 ? '' : 's') + ' Shown'} icon={portrait ? 'https://render.worldofwarcraft.com/' + portrait.region.toLowerCase() + '/character/' + portrait.thumbnail : null}>
                    <div className="d-flex flex-wrap">
                        <div className={'btn-group' + (window.bootstrap.md.matches ? ' mr-3' : '-vertical w-100 mb-3')  + ' flex-wrap'}>
                            <input type="text" placeholder="Search" className={'form-control ' + (window.bootstrap.md.matches ? '' : 'flex-fill')} value={this.state.filter} onChange={this.changeFilter} />
                        </div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? ' mr-3' : '-vertical w-100 mb-3')  + ' flex-wrap'}>
                            <button type="button" className={this.state.showActive ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleActive}><FontAwesomeIcon icon={this.state.showActive ? faCheckCircle : faCircle} /> Active</button>
                            <button type="button" className={this.state.showNotFound ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleNotFound}><FontAwesomeIcon icon={this.state.showNotFound ? faCheckCircle : faCircle} /> Missing</button>
                            {this.props.from === 'oauth' ? <button type="button" className={this.state.showHidden ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleHidden}><FontAwesomeIcon icon={this.state.showHidden ? faCheckCircle : faCircle} /> Hidden</button> : null}
                        </div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? ' mr-3' : '-vertical w-100 mb-3')  + ' flex-wrap'}>
                            <button type="button" className="btn btn-primary" onClick={this.toggleFactions}>Faction <FontAwesomeIcon icon={this.state.hideFactions.size === 0 ? (this.state.showFactions ? faChevronUp : faChevronDown) : (this.state.showFactions ? faChevronCircleUp : faChevronCircleDown)} /></button>
                            <button type="button" className="btn btn-primary" onClick={this.toggleRaces}>Race <FontAwesomeIcon icon={this.state.hideRaces.size === 0 ? (this.state.showRaces ? faChevronUp : faChevronDown) : (this.state.showRaces ? faChevronCircleUp : faChevronCircleDown)} /></button>
                            <button type="button" className="btn btn-primary" onClick={this.toggleClasses}>Class <FontAwesomeIcon icon={this.state.hideClasses.size === 0 ? (this.state.showClasses ? faChevronUp : faChevronDown) : (this.state.showClasses ? faChevronCircleUp : faChevronCircleDown)} /></button>
                            <button type="button" className="btn btn-primary" onClick={this.toggleProfessions}>Profession <FontAwesomeIcon icon={this.state.hideProfessions.size === 0 ? (this.state.showProfessions ? faChevronUp : faChevronDown) : (this.state.showProfessions ? faChevronCircleUp : faChevronCircleDown)} /></button>
                            <button type="button" className="btn btn-primary" onClick={this.toggleLevels}>Level <FontAwesomeIcon icon={!this.state.levelMin && !this.state.levelMax ? (this.state.showLevels ? faChevronUp : faChevronDown) : (this.state.showLevels ? faChevronCircleUp : faChevronCircleDown)} /></button>
                            <button type="button" className="btn btn-primary" onClick={this.toggleItemLevels}>Item Level <FontAwesomeIcon icon={!this.state.itemLevelMin && !this.state.itemLevelMax ? (this.state.showItemLevels ? faChevronUp : faChevronDown) : (this.state.showItemLevels ? faChevronCircleUp : faChevronCircleDown)} /></button>
                            {this.state.wowaccounts?.length > 1 ? <button type="button" className="btn btn-primary" onClick={this.toggleAccounts}>Account <FontAwesomeIcon icon={this.state.hideAccounts.size === 0 ? (this.state.showAccounts ? faChevronUp : faChevronDown) : (this.state.showAccounts ? faChevronCircleUp : faChevronCircleDown)} /></button> : null}
                        </div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? ' mr-3' : '-vertical w-100 mb-3')  + ' flex-wrap'}>
                            <button type="button" className="btn btn-primary" onClick={this.toggleSort}>Sort <FontAwesomeIcon icon={this.state.showSort ? faChevronUp : faChevronDown} /></button>
                        </div>
                        {this.props.from === 'favorites' ?
                            <div className={'btn-group' + (window.bootstrap.md.matches ? ' mr-3' : '-vertical w-100 mb-3')  + ' flex-wrap'}>
                                <Link className="btn btn-primary" to={'/leaderboards/completion-score/custom/characters/' + encodeURIComponent(utils.pack(leaderboards))}>Leaderboards</Link>
                            </div>
                        : null}
                        {this.props.from === 'oauth' && this.state.character && !this.state.exclusive ?
                            <div className={'btn-group' + (window.bootstrap.md.matches ? ' mr-3' : '-vertical w-100 mb-3')  + ' flex-wrap'}>
                                <Tooltip title="Analyzes your characters and displays an icon if they have collection items that no other character has. May take a few moments to run and results won't be 100% accurate due to limitations of Blizzard's API. Use at your own risk.">
                                    <form method="post" onSubmit={this.exclusive} className={window.bootstrap.md.matches ? null : 'w-100'}>
                                        <button type="submit" className={'btn btn-primary text-nowrap' + (window.bootstrap.md.matches ? '' : ' w-100') + (this.state.loadingExclusive ? ' disabled' : '')}>Exclusive</button>
                                    </form>
                                </Tooltip>
                            </div>
                        : null}
                        {this.state.favorites ?
                            <div className={window.bootstrap.md.matches ? 'ml-auto' : 'w-100'}>
                                <Tooltip title="Update all characters from Warcraft armory">
                                    <form method="post" onSubmit={this.refreshAll} className={window.bootstrap.md.matches ? null : 'w-100'}>
                                        <button type="submit" className={'btn btn-primary text-nowrap' + (window.bootstrap.md.matches ? '' : ' w-100') + (this.state.loadingAll ? ' disabled' : '')}><FontAwesomeIcon icon={faSync} spin={this.state.loadingAll} /> Update All</button>
                                    </form>
                                </Tooltip>
                            </div>
                        : null}
                    </div>
                    {this.state.showFactions ?
                        <>
                        <div className="small mt-3">Faction</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mt-1 mr-3'}>
                            <button type="button" className="btn btn-primary" onClick={this.toggleFactions}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                            <ToggleButtonWrapper id={undefined} label="Unknown" property="hideFactions" checked={!this.state.hideFactions.has(undefined)} onToggle={this.toggleFilter} />
                            <ToggleButtonWrapper id={0} label="Alliance" property="hideFactions" checked={!this.state.hideFactions.has(0)} onToggle={this.toggleFilter} />
                            <ToggleButtonWrapper id={1} label="Horde" property="hideFactions" checked={!this.state.hideFactions.has(1)} onToggle={this.toggleFilter} />
                        </div>
                        </>
                    : null}
                    {this.state.showRaces ?
                        <>
                        <div className="small mt-3">Race</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mt-1 mr-3'}>
                            <button type="button" className="btn btn-primary" onClick={this.toggleRaces}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                            <ToggleButtonWrapper id="All" property="hideRaces" variants={RACES.map(c => c.key)} onToggle={this.toggleFilter} />
                            <ToggleButtonWrapper id="None" property="hideRaces" variants={RACES.map(c => c.key)} onToggle={this.toggleFilter} />
                            {RACES.map(c => <ToggleButtonWrapper key={c.key || 'undefined'} id={c.key} label={c.value} property="hideRaces" checked={!this.state.hideRaces.has(c.key)} onToggle={this.toggleFilter} />)}
                        </div>
                        </>
                    : null}
                    {this.state.showClasses ?
                        <>
                        <div className="small mt-3">Class</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mt-1 mr-3'}>
                            <button type="button" className="btn btn-primary" onClick={this.toggleClasses}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                            <ToggleButtonWrapper id="All" property="hideClasses" variants={CLASSES.map(c => c.key)} onToggle={this.toggleFilter} />
                            <ToggleButtonWrapper id="None" property="hideClasses" variants={CLASSES.map(c => c.key)} onToggle={this.toggleFilter} />
                            {CLASSES.map(c => <ToggleButtonWrapper key={c.key || 'undefined'} id={c.key} label={c.value} property="hideClasses" checked={!this.state.hideClasses.has(c.key)} onToggle={this.toggleFilter} />)}
                        </div>
                        </>
                    : null}
                    {this.state.showProfessions ?
                        <>
                        <div className="small mt-3">Profession</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mt-1 mr-3'}>
                            <button type="button" className="btn btn-primary" onClick={this.toggleProfessions}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                            <ToggleButtonWrapper id="All" property="hideProfessions" variants={PROFFILTERS.map(p => p.key)} onToggle={this.toggleFilter} />
                            <ToggleButtonWrapper id="None" property="hideProfessions" variants={PROFFILTERS.map(p => p.key)} onToggle={this.toggleFilter} />
                            {PROFFILTERS.map(p => <ToggleButtonWrapper key={p.key || 'undefined'} id={p.key} label={p.value} property="hideProfessions" checked={!this.state.hideProfessions.has(p.key)} onToggle={this.toggleFilter} />)}
                        </div>
                        </>
                    : null}
                    {this.state.showLevels ?
                        <>
                        <div className="small mt-3">Level</div>
                        <div className="mt-1">
                            <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100 mb-3')  + ' flex-wrap mr-3'}>
                                <button type="button" className="btn btn-primary" onClick={this.toggleLevels}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                            </div>
                            <div className="btn-group mr-3">
                                <div className="my-auto"><NumberInput placeholder="Min" className="form-control" value={this.state.levelMin} onChange={this.changeLevelMin} /></div>
                                <div className="my-auto mx-1">-</div>
                                <div className="my-auto"><NumberInput placeholder="Max" className="form-control" value={this.state.levelMax} onChange={this.changeLevelMax} /></div>
                            </div>
                        </div>
                        </>
                    : null}
                    {this.state.showItemLevels ?
                        <>
                        <div className="small mt-3">Item Level</div>
                        <div className="mt-1">
                            <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100 mb-3')  + ' flex-wrap mr-3'}>
                                <button type="button" className="btn btn-primary" onClick={this.toggleItemLevels}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                            </div>
                            <div className="btn-group mr-3">
                                <div className="my-auto"><NumberInput placeholder="Min" className="form-control" value={this.state.itemLevelMin} onChange={this.changeItemLevelMin} /></div>
                                <div className="my-auto mx-1">-</div>
                                <div className="my-auto"><NumberInput placeholder="Max" className="form-control" value={this.state.itemLevelMax} onChange={this.changeItemLevelMax} /></div>
                            </div>
                        </div>
                        </>
                    : null}
                    {this.state.showAccounts ?
                        <>
                        <div className="small mt-3">Account</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mt-1 mr-3'}>
                            <button type="button" className="btn btn-primary" onClick={this.toggleAccounts}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                            <ToggleButtonWrapper id="All" property="hideAccounts" variants={[ 0, ...(this.state.wowaccounts || []) ]} onToggle={this.toggleFilter} />
                            <ToggleButtonWrapper id="None" property="hideAccounts" variants={[ 0, ...(this.state.wowaccounts || []) ]} onToggle={this.toggleFilter} />
                            <ToggleButtonWrapper key={0} id={0} label="Unknown" property="hideAccounts" checked={!this.state.hideAccounts.has(0)} onToggle={this.toggleFilter} />
                            {this.state.wowaccounts?.map(a => <ToggleButtonWrapper key={a} id={a} label={accountName(a)} property="hideAccounts" checked={!this.state.hideAccounts.has(a)} onToggle={this.toggleFilter} />)}
                        </div>
                        </>
                    : null}
                    {this.state.showSort ?
                        <>
                        <div className="small mt-3">Sort</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mt-1 mr-3'}>
                            <button type="button" className="btn btn-primary" onClick={this.toggleSort}><FontAwesomeIcon icon={window.bootstrap.md.matches ? faChevronUp : faTimes} /></button>
                            <button type="button" className={this.state.sort.startsWith('manual') ? 'btn btn-primary active' : 'btn btn-primary'} data-sort="manual" onClick={this.sort}><FontAwesomeIcon icon={this.state.sort === 'manual' ? faCheckCircle  : faCircle} /> Manual</button>
                            <button type="button" className={this.state.sort.startsWith('realm') ? 'btn btn-primary active' : 'btn btn-primary'} data-sort="realm" onClick={this.sort}><FontAwesomeIcon icon={this.state.sort === 'realm-asc' ? faArrowAltCircleDown : (this.state.sort === 'realm-desc' ? faArrowAltCircleUp : faCircle)} /> Realm</button>
                            <button type="button" className={this.state.sort.startsWith('guild') ? 'btn btn-primary active' : 'btn btn-primary'} data-sort="guild" onClick={this.sort}><FontAwesomeIcon icon={this.state.sort === 'guild-asc' ? faArrowAltCircleDown : (this.state.sort === 'guild-desc' ? faArrowAltCircleUp : faCircle)} /> Guild</button>
                            <button type="button" className={this.state.sort.startsWith('name') ? 'btn btn-primary active' : 'btn btn-primary'} data-sort="name" onClick={this.sort}><FontAwesomeIcon icon={this.state.sort === 'name-asc' ? faArrowAltCircleDown : (this.state.sort === 'name-desc' ? faArrowAltCircleUp : faCircle)} /> Name</button>
                            <button type="button" className={this.state.sort.startsWith('level') ? 'btn btn-primary active' : 'btn btn-primary'} data-sort="level" onClick={this.sort}><FontAwesomeIcon icon={this.state.sort === 'level-asc' ? faArrowAltCircleDown : (this.state.sort === 'level-desc' ? faArrowAltCircleUp : faCircle)} /> Level</button>
                            <button type="button" className={this.state.sort.startsWith('wowaccount') ? 'btn btn-primary active' : 'btn btn-primary'} data-sort="wowaccount" onClick={this.sort}><FontAwesomeIcon icon={this.state.sort === 'wowaccount-asc' ? faArrowAltCircleDown : (this.state.sort === 'wowaccount-desc' ? faArrowAltCircleUp : faCircle)} /> Account</button>
                        </div>
                        </>
                    : null}
                    {typeof this.state.loadingAll === 'number' ? <div className="mt-3 p-2 rounded rankings"><FontAwesomeIcon icon={faInfoCircle} /> {this.state.loadingAll} characters have been added to the queue. You can leave this page and the server will continue to process your updates.</div> : null}
                </Section>

                <div className="m-3 card">
                    <table className="table table-hover m-0">
                        <thead className="thead-dark">
                            <tr>
                                <th width="2%">&nbsp;</th>
                                <th width="2%">&nbsp;</th>
                                <th width={width} className="align-middle">Character</th>
                                {window.bootstrap.md.matches ? <>
                                    <th width={width} className="align-middle">Professions</th>
                                    <th width={width} className="align-middle">Guild</th>
                                    <th width={width} className="align-middle">Realm</th>
                                    <th width="2%">&nbsp;</th>
                                </> : null}
                            </tr>
                        </thead>
                        <tbody>{table}</tbody>
                    </table>
                </div>

                {this.props.from === 'oauth' ? <Guilds list={this.state.filteredGuilds} /> : null}
            </div>
        );
    }
}

export default Team;