import React, { Component } from 'react';

class Progress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            character: 0,
            account: 0,
            obtainable: 0,
            total: 0,
            pcharacter: 0,
            paccount: 0,
            pobtainable: 0,
            ptotal: 0
        };
    }

    componentDidMount() {
        this.compute();
    }

    componentDidUpdate(previous) {
        if (previous.collection === this.props.collection && previous.character === this.props.character) return;
        this.compute();
    }

    compute() {
        let character = this.props.character || 0;
        let account = this.props.account || 0;
        let obtainable = this.props.obtainable || 0;
        let total = this.props.total || 0;

        if (this.props.collection) {
            const collection = this.props.collection.filter(item => !item.excluded);

            character = collection.filter(item => item.character).length;
            account = collection.filter(item => item.account).length;
            obtainable = collection.filter(item => item.account || !item.unobtainable || item.unobtainable === 3).length;
            total = collection.filter(item => item.account || item.unobtainable !== 2).length;
        }

        const pcharacter = (100 * character / total);
        const paccount = (100 * account / total) - pcharacter;
        const pobtainable = (100 * obtainable / total) - paccount - pcharacter;
        const ptotal = 100 - pobtainable - paccount - pcharacter;

        this.setState({ character, account, obtainable, total, pcharacter, paccount, pobtainable, ptotal });
    }

    render() {
        return (
            <div className="mb-3" style={{ minHeight: '5em' }}>
                <div className="progress bg-dark">
                    <div className="progress-bar bg-dark text-right pr-1" style={{ width: '100%' }} role="progressbar" aria-valuenow={100} aria-valuemin="0" aria-valuemax="100">{this.state.total.toLocaleString()} Total {this.props.label}</div>
                </div>
                <div className="progress">
                    <div className="progress-bar border-right bg-success text-right" style={{ width: this.state.pcharacter + '%' }} role="progressbar" aria-valuenow={this.state.pcharacter} aria-valuemin="0" aria-valuemax="100"></div>
                    <div className="progress-bar border-right progress-bar-striped bg-success text-right" style={{ width: this.state.paccount + '%' }} role="progressbar" aria-valuenow={this.state.paccount} aria-valuemin="0" aria-valuemax="100"></div>
                    <div className="progress-bar border-right progress-bar-striped bg-dark text-right" style={{ width: this.state.pobtainable + '%' }} role="progressbar" aria-valuenow={this.state.pobtainable} aria-valuemin="0" aria-valuemax="100"></div>
                    <div className="progress-bar progress-bar-striped bg-danger text-right" style={{ width: this.state.ptotal + '%' }} role="progressbar" aria-valuenow={this.state.ptotal} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                {(this.state.paccount < 1) ? null :
                    <div className="progress rounded-0 bg-dark">
                        <div className="progress-bar bg-dark text-right border-right pr-1" style={{ width: this.state.pcharacter + '%' }} role="progressbar" aria-valuenow={this.state.pcharacter} aria-valuemin="0" aria-valuemax="100">{this.state.character.toLocaleString()} Character {this.props.label}</div>
                        <div className="progress-bar bg-dark border-right" style={{ width: this.state.paccount + '%' }} role="progressbar" aria-valuenow={this.state.paccount} aria-valuemin="0" aria-valuemax="100"></div>
                        <div className="progress-bar bg-dark border-right" style={{ width: this.state.pobtainable + '%' }} role="progressbar" aria-valuenow={this.state.pobtainable} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                }
                <div className="progress rounded-0 bg-dark">
                    <div className="progress-bar bg-dark text-right border-right pr-1" style={{ width: (this.state.pcharacter + this.state.paccount) + '%' }} role="progressbar" aria-valuenow={this.state.pcharacter + this.state.paccount} aria-valuemin="0" aria-valuemax="100">{this.state.account.toLocaleString()} {this.props.label === 'Pets' ? 'Unique' : 'Account'} {this.props.label}</div>
                    <div className="progress-bar bg-dark border-right" style={{ width: this.state.pobtainable + '%' }} role="progressbar" aria-valuenow={this.state.pobtainable} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div className="progress rounded-0 bg-dark">
                    <div className="progress-bar bg-dark text-right border-right pr-1" style={{ width: (this.state.pcharacter + this.state.paccount + this.state.pobtainable) + '%' }} role="progressbar" aria-valuenow={this.state.pcharacter + this.state.paccount + this.state.pobtainable} aria-valuemin="0" aria-valuemax="100">{this.state.obtainable.toLocaleString()} Obtainable {this.props.label}</div>
                </div>
            </div>
        );
    }
}

export default Progress;