import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import favorite from '../util/favorite';

class MoveButton extends Component {
    handleClick = (event) => {
        if (event) event.preventDefault(); // prevent form submission
        this.move(this.props.idx, this.props.to);
    }

    handleContext = (event) => {
        if (event) event.preventDefault(); // prevent form submission
        this.move(this.props.idx, this.props.toMax);
    }

    move = (idx, to) => {
        const store = favorite.get(this.props.favkey);
        const list = store.getFavorites();

        const elem = list[idx];
        list.splice(idx, 1);
        list.splice(to, 0, elem);

        store.setFavorites(list);
        this.props.onChange(list);
    }

    render() {
        return (
            <button className="btn btn-link" onClick={this.handleClick} onContextMenu={this.handleContext}><FontAwesomeIcon icon={this.props.direction === 'up' ? faChevronUp : faChevronDown} /></button>
        );
    }
}

export default MoveButton;