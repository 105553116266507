import React, { Component } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';

import favorite from '../util/favorite';

class FavoriteButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            favorite: false
        };
    }

    componentDidMount() {
        this.lookup();
    }

    componentDidUpdate(previous) {
        if (this.props.region === previous.region && this.props.realm === previous.realm && this.props.name === previous.name) return;
        this.lookup();
    }

    lookup() {
        const f = favorite.get('favorites');
        const fav = f.isFavorite(this.props.region, this.props.realm, this.props.name);
        if (fav && this.props.thumbnail) {
            // keep favorites up-to-date
            f.updateFavorite(this.props.region, this.props.realm, this.props.name, this.props.class, this.props.thumbnail);
        }

        this.setState({ favorite: fav });
    }

    toggleFavorite = () => {
        const f = favorite.get('favorites');
        if (f.isFavorite(this.props.region, this.props.realm, this.props.name)) {
            f.removeFavorite(this.props.region, this.props.realm, this.props.name);
            this.setState({ favorite: false });
        } else {
            f.addFavorite(this.props.region, this.props.realm, this.props.name, this.props.class, this.props.thumbnail);
            this.setState({ favorite: true });
        }
    }

    render() {
        return (
            <Tooltip title={this.state.favorite ? 'Favorite character (click to remove)' : 'Add to favorite characters'}>
                <button type="button" className="btn btn-primary" onClick={this.toggleFavorite}>
                    <FontAwesomeIcon icon={this.state.favorite ? fasStar : farStar} />
                </button>
            </Tooltip>
        );
    }
}

export default FavoriteButton;