import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Subregion from './Subregion';

export default class SubregionLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subregion: ''
        };
    }

    setSubregion = (subregion) => {
        this.setState({ subregion });
    }

    handleSubmit = (event) => {
        if (event) event.preventDefault(); // prevent form submission

        if (this.state.subregion === '') {
            alert('Please select a subregion');
            return;
        }

        let idx = this.state.subregion.indexOf('-');
        let subregion = this.state.subregion.substring(idx + 1);

        this.props.history.push('/leaderboards/' + encodeURIComponent(this.props.category) + '/subregion/' + encodeURIComponent(subregion));

        return false;
    }

    render() {
        return (
            <form className="form-inline input-group realmlookup" onSubmit={this.handleSubmit}>
                <Subregion onChange={this.setSubregion} />
                <div className="input-group-append">
                    <button type="submit" className="btn btn-primary"><FontAwesomeIcon icon={faChevronRight} /></button>
                </div>
            </form>
        );
    }
}