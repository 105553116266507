import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

class Toast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true
        };
    }

    close = () => {
        this.setState({ show: false });
    }

    render() {
        if (!this.state.show) return null;
        if (this.props.show === false) return null;

        return (
            <div className="mx-3 mt-3 mb-0 alert alert-warning d-flex">
                <div className="my-auto">
                    {this.props.children}
                </div>
                <div className="ml-auto">
                    <button type="button" className="btn btn-primary" onClick={this.close}><FontAwesomeIcon icon={faTimes} /></button>
                </div>
            </div>
        );
    }
}

export default Toast;