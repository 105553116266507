import React from 'react';

function Section(props) {
    return (
        <div className="card m-3">
            <div className="card-header">
                <div className="d-flex">
                    {props.icon ? <div className="mr-3"><img className="rounded" src={props.icon} alt="Icon" height="84" width="84" /></div> : null}
                    <div className="w-100">
                        <div className="d-flex">
                            <div className="mr-auto"><h1 className={'m-0 ' + props.color || ''}>{props.title}</h1></div>
                            {window.bootstrap.md.matches ? props.buttons : null}
                        </div>
                        <div className="d-flex">
                            <div className="mr-auto my-auto"><h5>{props.subtitle}</h5></div>
                            {window.bootstrap.md.matches ? props.icons : null}
                        </div>
                    </div>
                    {props.close ? <div className="ml-auto text-nowrap">{props.close}</div> : null}
                </div>
                {props.icons && !window.bootstrap.md.matches ? <div className="d-flex justify-content-center my-2">{props.icons}</div> : null}
                {!window.bootstrap.md.matches ? props.buttons : null}
                {props.intro ? <div className="small mt-3">{props.intro}</div> : null}
            </div>
            <div className={'card-body' + (props.padding === 'off' ? ' p-0' : '')}>{props.children}</div>
            {props.footer ? <div className="card-footer small">{props.footer}</div> : null}
        </div>
    );
}

export default Section;