import React, { Component } from 'react';
import Autocomplete from '@mui/material/Autocomplete';

import './Subregion.scss';

export default class Subregion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            subregions: [
                'EU-English',
                'EU-French',
                'EU-German',
                'EU-Italian',
                'EU-Russian',
                'EU-Spanish',
                'KR-Korea',
                'TW-Global',
                'TW-Taiwan',
                'US-Brazil',
                'US-Latin America',
                'US-Oceanic',
                'US-United States'
            ]
        };
    }

    onChange = (event, value, reason, details) => {
        this.setState({ value });
        this.props.onChange(value);
    }

    render() {
        return (
            <Autocomplete
                disablePortal
                selectOnFocus
                clearOnBlur
                options={this.state.subregions}
                onChange={this.onChange}
                renderInput={(params) => <div ref={params.InputProps.ref}><input type="text" {...params.inputProps} placeholder="Subregion" className="form-control subregion" /></div>}
            />
        );
    }
}