import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedditAlien, faTwitter, faTwitch, faPatreon, faDiscord, faYoutube } from '@fortawesome/free-brands-svg-icons';


class Footer extends Component {
    render() {
        return (
            <div className="bar py-3">
                <div className={window.bootstrap.md.matches ? 'd-flex flex-wrap' : 'text-center'}>
                    <div className="mx-3"><Link to="/collections">Browse Collections &amp; Rarity</Link></div>
                    <div className="mx-3"><Link to="/stats">Statistics</Link></div>
                    <div className="mx-3"><Link to="/calculator">Gold Calculator</Link></div>
                    <div className="mx-3"><Link to="/minigames">Solar Collector</Link></div>

                    <div className="flex-fill"></div>

                    <div className="mx-3"><Link to="/about">About &amp; FAQ</Link></div>
                    <div className="mx-3"><Link to="/tickets">Issue Tracker</Link></div>
                    <div className="mx-3"><Link to="/brand">Logos</Link></div>
                    <div className="mx-3"><Link to="/privacy">Privacy Policy</Link></div>
                </div>
                <div className={window.bootstrap.md.matches ? 'd-flex flex-wrap mt-2' : 'text-center mt-5'}>
                    <div className="mx-3">Built by <a href="http://www.shoogen.com/" target="external" className="class-8">Shoogen</a> &copy; 2019 - 2024</div>

                    <div className="flex-fill">
                        <div className="d-none"><Tooltip title="Tooltip Fix"><span></span></Tooltip></div>
                    </div>

                    <div className="mx-1"><a href="https://www.reddit.com/r/dataforazeroth/" target="external"><FontAwesomeIcon icon={faRedditAlien} className="mr-2" />{window.bootstrap.md.matches ? '' : 'Reddit'}</a></div>
                    <div className="mx-1"><a href="https://discord.gg/7Mmjxdh" target="external"><FontAwesomeIcon icon={faDiscord} className="mr-2" />{window.bootstrap.md.matches ? '' : 'Discord'}</a></div>
                    <div className="mx-1"><a href="https://twitch.tv/shoogen" target="external"><FontAwesomeIcon icon={faTwitch} className="mr-2" />{window.bootstrap.md.matches ? '' : 'Twitch'}</a></div>
                    <div className="mx-1"><a href="https://youtube.com/channel/UCGwu5m78_yV5nW_oshxSWtQ" target="external"><FontAwesomeIcon icon={faYoutube} className="mr-2" />{window.bootstrap.md.matches ? '' : 'YouTube'}</a></div>
                    <div className="mx-1"><a href="https://twitter.com/shoogentweets" target="external"><FontAwesomeIcon icon={faTwitter} className="mr-2" />{window.bootstrap.md.matches ? '' : 'Twitter'}</a></div>
                    <div className="mx-1"><a href="https://www.patreon.com/shoogen" target="external"><FontAwesomeIcon icon={faPatreon} className="mr-2" />{window.bootstrap.md.matches ? '' : 'Patreon'}</a></div>
                </div>
            </div>
        );
    }
}

export default Footer;
