import React, { Component } from 'react';
import Autocomplete from '@mui/material/Autocomplete';

import api from '../util/api';

import './Realm.scss';

class Realm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            realms: []
        };
    }

    componentDidMount() {
        this.loadRealms();
    }

    async loadRealms() {
        const json = await api.web('realms');
        const realms = json.realms.map(realm => realm.region.toUpperCase() + '-' + realm.name);
        this.setState({ realms });
    }

    onChange = (event, value, reason, details) => {
        this.setState({ value });
        this.props.onChange(value);
    }

    render() {
        return (
            <Autocomplete
                disablePortal
                selectOnFocus
                clearOnBlur
                options={this.state.realms}
                onChange={this.onChange}
                renderInput={(params) => <div ref={params.InputProps.ref}><input type="text" {...params.inputProps} placeholder="Realm" className="form-control realm" /></div>}
            />
        );
    }
}

export default Realm;
