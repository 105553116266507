import React, { Component } from 'react';

export default class HistoryButton extends Component {
    click = (event) => {
        this.props.onClick(this.props.id);
    }

    render() {
        return (
            <button className={this.props.active === this.props.id ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.click}>{this.props.label}</button>
        );
    }
}