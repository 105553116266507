import React, { Component } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faBattleNet } from '@fortawesome/free-brands-svg-icons';

const clientIds = {
    us: 'fa9b33c953d549c58b028e3e588f533e',
    eu: 'e9332d2f25554b959db636ad0bdd3ba8',
    kr: '2988c38da1c84c5d863f5f3fb353dce0',
    tw: '6d2077e92c6a48628d2de41b1cd50aaa'
};

export default class LoginButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: false
        };
    }

    oauthUrl(region) {
        const stage = (window.location.hostname === 'dev.dataforazeroth.com') ? 'http://dev.dataforazeroth.com:3000/redirect' : 'https://www.dataforazeroth.com/redirect';
        const state = 'blizzard' + region + Date.now().toString(36).slice(-5);
        localStorage.setItem('state.blizzard', state);
        return 'https://oauth.battle.net/oauth/authorize?client_id=' + encodeURIComponent(clientIds[region]) + '&scope=wow.profile&state=' + encodeURIComponent(state) + '&redirect_uri=' + encodeURIComponent(stage) + '&response_type=code';
    }

    characters = () => {
        const token = JSON.parse(localStorage.getItem('token.blizzard'));
        if (!token.region || !token.expires_at) {
            // invalid token
            return this.logout();
        } else if (token.expires_at < Date.now()) {
            // expired token
            document.location.href = this.oauthUrl(token.region);
        } else {
            // already logged in
            this.props.history.push('/mycharacters');
        }
    }

    logout = () => {
        localStorage.removeItem('token.patreon');
        localStorage.removeItem('token.twitch');
        localStorage.removeItem('token.blizzard');

        this.props.history.push('/');
    }

    toggleLogin = (event) => {
        this.setState({ login: this.state.login ? false : event.currentTarget });
    }

    closeLogin = () => {
        this.setState({ login: false });
    }

    render() {
        const loggedin = localStorage.getItem('token.blizzard');
        if (loggedin) {
            return (
                <div className="btn-group">
                    <Tooltip title="View your characters using Battle.net login">
                        <button type="button" className="btn btn-primary" onClick={this.characters}>
                            <FontAwesomeIcon icon={faUsers} />
                        </button>
                    </Tooltip>
                    <Tooltip title="Logout">
                        <button type="button" className="btn btn-primary" onClick={this.logout}>
                            <FontAwesomeIcon icon={faSignOutAlt} />
                        </button>
                    </Tooltip>
                </div>
            );
        } else {
            return (
                <div>
                    <Tooltip title={this.state.login ? null : 'View your characters using Battle.net login'}>
                        <button type="button" className="btn btn-primary" onClick={this.toggleLogin}><FontAwesomeIcon icon={faBattleNet} />{window.bootstrap.lg.matches ? ' Login' : null}</button>
                    </Tooltip>
                    <Popper open={Boolean(this.state.login)} anchorEl={this.state.login} placement="bottom-end">
                        <ClickAwayListener onClickAway={this.closeLogin}>
                            <div className="btn-group-vertical mt-1">
                                {[ 'eu', 'kr', 'tw', 'us' ].map(region => <a key={region} href={this.oauthUrl(region)} className="btn btn-primary px-5 w-100 text-center">{region.toUpperCase()}</a>)}
                            </div>
                        </ClickAwayListener>
                    </Popper>
                </div>
            );
        }
    }
}