import React from 'react';

import Section from '../components/Section';
import Loading from '../components/Loading';

function OAuthError(props) {
    return (
        <Section title="Login Failed">
            <Loading message="Login failed" />

            <p>Your login attempt failed. Please try again, and ensure that you have granted <i>Data for Azeroth</i> the correct permissions.</p>

            <p>Sometimes there are temporary errors with the Blizzard API. If all else fails, please wait a few hours and try again.</p>
        </Section>
    );
}

export default OAuthError;