import React, { Component } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash as farEyeSlash } from '@fortawesome/free-regular-svg-icons';

export default class HideButton extends Component {
    handleClick = (event) => {
        if (event) event.preventDefault(); // prevent form submission
        this.props.onClick(this.props.region, this.props.realm, this.props.character, !this.props.hidden);
        return false;
    }

    render() {
        return (
            <form method="post" onSubmit={this.handleClick}>
                <button type="submit" disabled={this.props.disabled} className="btn btn-primary">
                    <Tooltip title={this.props.hidden ? 'Show this character' : 'Hide this character'}>
                        <span><FontAwesomeIcon icon={this.props.hidden ? faEyeSlash : farEyeSlash} /></span>
                    </Tooltip>
                </button>
            </form>
        );
    }
}