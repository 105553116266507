import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons';

import favorite from '../util/favorite';

class FavoriteDown extends Component {
    render() {
        const rows = [];
        const favorites = favorite.get('favorites').getFavorites();

        for (let i = 0; i < 5 && i < favorites.length; i++) {
            const character = favorites[i];

            rows.push(
                <tr key={'row' + i}>
                    <td className="align-middle p-2 my-auto" width="1%">
                        {character.thumbnail ? <img className="rounded" style={{width:'2em',height:'2em'}} src={'https://render.worldofwarcraft.com/' + character.region.toLowerCase() + '/character/' + character.thumbnail} alt="" /> : null}
                    </td>
                    <td className="align-middle p-0 pr-5 my-auto">
                        <div><Link className={'class-' + character.class} to={'/characters/' + encodeURIComponent(character.region) + '/' + encodeURIComponent(character.realm) + '/' + encodeURIComponent(character.character)}>{character.character}</Link></div>
                        <div className="small">{character.region.toUpperCase()}-{character.realm}</div>
                    </td>
                </tr>
            );
        }

        if (!rows.length) {
            rows.push(
                <tr key="empty">
                    <td className="small">Please choose your favorite characters by clicking the <FontAwesomeIcon icon={faStar} /> button on their profile.</td>
                </tr>
            )
        }

        return (
            <div className="favorites">
                <div className="card">
                    <div className="card-header p-2 text-center">
                        <Link to="/favorites">Favorites</Link>
                    </div>
                    <table className="table m-0"><tbody>{rows}</tbody></table>
                    <div className="card-footer p-2 text-center">
                        <Link to="/favorites">More...</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default FavoriteDown;
