import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';

import Loading from '../components/Loading';

import login from '../util/login';
import api from '../util/api';
import lua from '../util/lua';

export default class AddonUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploading: false
        };

        // remove old keys
        localStorage.removeItem('collection.appearances.' + login.account());
        localStorage.removeItem('collection.appearances.' + login.account() + '.updated');
    }

    upload = async (event) => {
        if (event.target.files) {
            this.setState({ uploading: true });
            try {
                const parsed = lua.parse(await event.target.files[0].text());

                const mset = new Set();
                (await api.web('manuscripts')).manuscripts.forEach(m => mset.add(m.id + ''));

                const now = Date.now();
                if (parsed.ATTAccountWideData) {
                    let manuscripts = parsed.ATTAccountWideData.Quests;
                    manuscripts = Object.keys(manuscripts).filter(key => mset.has(key) && manuscripts[key]).map(key => parseInt(key));
                    localStorage.setItem('collection.manuscripts.' + login.account(), JSON.stringify(manuscripts));
                    localStorage.setItem('collection.manuscripts.' + login.account() + '.updated', now);

                    let appearances = parsed.ATTAccountWideData.Sources;
                    appearances = Object.keys(appearances).filter(key => appearances[key] === 1).map(key => parseInt(key));
                    localStorage.setItem('collection.appearancesources.' + login.account(), JSON.stringify(appearances));
                    localStorage.setItem('collection.appearancesources.' + login.account() + '.updated', now);
                } else if (parsed.ManuscriptsJournalExportDB) {
                    let manuscripts = parsed.ManuscriptsJournalExportDB.learnedManuscripts;
                    manuscripts = Object.keys(manuscripts).filter(key => mset.has(key) && manuscripts[key]).map(key => parseInt(key));
                    localStorage.setItem('collection.manuscripts.' + login.account(), JSON.stringify(manuscripts));
                    localStorage.setItem('collection.manuscripts.' + login.account() + '.updated', now);
                } else {
                    throw new Error('no data');
                }

                this.props.onToggle('uploaded');
            } catch (e) {
                alert('Unrecognized SavedVariables file. If you believe this is a bug, please report it to the dataforazeroth discord.');
            }
            this.setState({ uploading: false });
        } else {
            localStorage.removeItem('collection.manuscripts.' + login.account());
            localStorage.removeItem('collection.manuscripts.' + login.account() + '.updated');
            localStorage.removeItem('collection.appearancesources.' + login.account());
            localStorage.removeItem('collection.appearancesources.' + login.account() + '.updated');
            this.props.onToggle('uploaded');
        }
    }

    noop() {

    }

    render() {
        const updated = parseInt(localStorage.getItem('collection.' + this.props.category + '.' + login.account() + '.updated'));
        const timestamp = updated ? <Tooltip title={'Last Updated: ' + moment(updated).format('lll')}><span><FontAwesomeIcon icon={faClock} className="mr-1" />{moment(updated).fromNow()}</span></Tooltip> : null;

        return (
            <>
                {this.state.uploading ? <Loading message="Loading..." /> : null}
                <div className="mb-3">
                    {this.props.category === 'appearancesources' ?
                        <>
                        Blizzard's API does not currently provide a list of collected appearance sources. Your leaderboard count is obtained from achievement data.
                        {this.props.enabled ? <> You can upload data from <a className="class-4" href="https://www.curseforge.com/wow/addons/all-the-things" target="external">ALL THE THINGS</a> to browse.</> : null}
                        <> <Link className="small" to="/about#upload">More Info...</Link></>
                        </>
                    : null}
                    {this.props.category === 'manuscripts' ?
                        <>
                        Blizzard's API does not currently provide a list of collected manuscripts.
                        {this.props.enabled ? <> You can upload data from <a className="class-4" href="https://www.curseforge.com/wow/addons/all-the-things" target="external">ALL THE THINGS</a> or <a className="class-11" href="https://www.curseforge.com/wow/addons/manuscripts-journal" target="external">Manuscripts Journal</a> to browse.</> : null}
                        <> <Link className="small" to="/about#upload">More Info...</Link></>
                        </>
                    : null}
                    
                </div>
                {this.props.enabled ?
                    <div className="text-center">
                        <div className={'btn-group' + (timestamp ? '' : ' mb-3')}>
                            <label className="btn btn-primary m-0">Browse <input type="file" accept=".lua" hidden onChange={this.upload} /></label>
                            <button type="button" className="btn btn-primary" onClick={this.upload}>Clear</button>
                        </div>
                        {timestamp ? <div className="small mb-3">{timestamp}</div> : null}
                    </div>
                : null}
            </>
        );
    }
}