import React, { Component } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

import api from '../util/api';

class GuildButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: false
        };
    }

    refreshGuild = async (event) => {
        if (event) event.preventDefault(); // prevent form submission

        this.setState({ status: 'loading' });

        const endpoint = 'guilds/' + encodeURIComponent(this.props.region) + '/' + encodeURIComponent(this.props.realm) + '/' + encodeURIComponent(this.props.name);
        const payload = { refresh: true };
        await api.post(endpoint, payload);

        this.setState({ status: 'hide' });

        return false;
    }

    render() {
        if (this.state.status === 'hide') return 'Added to Queue';

        return (
            <Tooltip title={this.state.status ? 'Please wait' : 'Import guild roster from Warcraft armory'}>
                <form method="post" onSubmit={this.refreshGuild}>
                    <button type="submit" disabled={this.state.status} className="btn btn-primary ml-2">{this.state.status ? <FontAwesomeIcon icon={faSync} spin={true} /> : 'Import'}</button>
                </form>
            </Tooltip>
        );
    }
}

export default GuildButton;