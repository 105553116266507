import React, { Component } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faExclamationTriangle, faFire } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';

import api from '../util/api';

class RefreshButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            token: null,
            position: 0
        };
    }

    handleClick = async (event) => {
        if (event) event.preventDefault(); // prevent form submission
        if (this.props.disabled || this.props.waiting) return false;

        if (this.props.onClick) this.props.onClick();
        this.setState({ loading: true });

        try {
            const response = await api.post('queue/add', [{ region: this.props.region, realm: this.props.realm, character: this.props.character }]);
            const json = await response.json();

            this.setState({ token: json[0] });
            window.setTimeout(this.status, 10000);
        } catch (e) {
            // TODO indicate error message?
            this.done();
        }

        return false;
    }

    status = async () => {
        try {
            const response = await api.get('queue/status/' + encodeURIComponent(this.state.token));
            const json = await response.json();

            if (json.status === 'DONE') {
                await this.props.onRefresh(this.props.region, this.props.realm, this.props.character);
                this.done();
            } else {
                this.setState({ position: json.position });
                window.setTimeout(this.status, 10000);
            }
        } catch (e) {
            // TODO indicate error message?
            this.done();
        }
    }

    done = () => {
        this.setState({ loading: false, token: null, position: 0 });
        if (this.props.onRefresh) this.props.onRefresh(); // empty call to reset onClick
    }

    render() {
        const queue = this.state.position ? '#' + this.state.position + ' in queue' : null;
        const exclusive = this.props.exclusive ? <Tooltip title={this.props.exclusive}><span><FontAwesomeIcon icon={faFire} className="mr-2" /></span></Tooltip> : null;
        const warning = this.props.warning ? <Tooltip title={this.props.warning}><span><FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" /></span></Tooltip> : null;
        const timestamp = <Tooltip title={'Last Updated: ' + (this.props.timestamp ? moment(this.props.timestamp).format('lll') : 'Never')}><span><FontAwesomeIcon icon={faClock} className="mr-1" />{this.props.timestamp ? moment(this.props.timestamp).fromNow() : 'Never'}</span></Tooltip>;
        const tooltip = (this.props.loading || this.state.loading || this.props.waiting) ? 'Please wait' : (this.props.disabled ? 'Update character is on cooldown' : 'Update character from Warcraft armory');

        return (
            <div className="d-flex ml-auto">
                <div className="my-auto ml-auto text-nowrap">{exclusive}{warning}{queue || this.props.message || timestamp}</div>
                <div className="my-auto">
                    <form method="post" onSubmit={this.handleClick}>
                        <button type="submit" disabled={this.props.loading || this.state.loading || this.props.disabled || this.props.waiting} className="btn btn-primary ml-2">
                            <Tooltip title={tooltip}>
                                <span><FontAwesomeIcon icon={faSync} spin={Boolean(this.props.loading || this.state.loading)} /></span>
                            </Tooltip>
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

export default RefreshButton;