import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';

import Realm from './Realm';

import utils from '../util/utils';
import api from '../util/api';

class RealmLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            realm: '',
            realms: new Set(),
            connected: localStorage.showConnected !== 'false'
        };
    }

    componentDidMount() {
        this.loadRealms();
    }

    async loadRealms() {
        const json = await api.web('realms');
        const realms = json.realms.map(realm => realm.region.toUpperCase() + '-' + realm.name);
        this.setState({ realms: new Set(realms) });
    }

    setRealm = (realm) => {
        this.setState({ realm });
    }

    toggleConnected = () => {
        localStorage.setItem('showConnected', this.state.connected ? 'false' : 'true');
        this.setState({ connected: !this.state.connected });
    }

    handleSubmit = (event) => {
        if (event) event.preventDefault(); // prevent form submission

        if (this.state.realm === '') {
            alert('Please select a realm');
            return;
        }

        if (!this.state.realms.has(this.state.realm)) {
            alert('Invalid realm name');
            return;
        }

        let idx = this.state.realm.indexOf('-');
        let region = this.state.realm.substring(0, idx);
        let realm = this.state.realm.substring(idx + 1);

        this.props.history.push('/leaderboards/' + encodeURIComponent(this.props.category) + '/' + encodeURIComponent(utils.clean(region)) + '/' + (this.state.connected ? 'connected-' : '') + encodeURIComponent(utils.slug(realm)));

        return false;
    }

    render() {
        return (
            <form className="form-inline input-group realmlookup" onSubmit={this.handleSubmit}>
                <div className="input-group-prepend">
                    <button type="button" className={this.state.connected ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleConnected}><FontAwesomeIcon icon={this.state.connected ? faCheckCircle : faCircle} /> Connected</button>
                </div>
                <Realm onChange={this.setRealm} />
                <div className="input-group-append">
                    <button type="submit" className="btn btn-primary"><FontAwesomeIcon icon={faChevronRight} /></button>
                </div>
            </form>
        );
    }
}

export default RealmLookup;
