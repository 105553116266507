import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import GuildButton from './GuildButton';

class Guilds extends Component {
    render() {
        // show nothing when there's no guilds
        if (this.props.list.length === 0) return null;

        const guildtable = [];

        for (let guild of this.props.list) {
            guildtable.push(
                <tr key={guild.region + '#' + guild.realm + '#' + guild.name}>
                    <td className="align-middle">
                        <div><Link to={'/leaderboards/completion-score/' + encodeURIComponent(guild.region) + '/' + encodeURIComponent(guild.realm) + '/' + encodeURIComponent(guild.name)}>{guild.name}</Link></div>
                        <div className="small">{guild.region.toUpperCase()}-{guild.realm}</div>
                    </td>
                    <td className="align-middle text-right">
                        <GuildButton region={guild.region} realm={guild.realm} name={guild.name} />
                    </td>
                </tr>
            );
        }

        if (guildtable.length === 0) {
            guildtable.push(
                <tr key="notfound">
                    <td>No matches found</td>
                </tr>
            );
        }

        return (
            <div className="m-3 card">
                <table className="table table-hover m-0">
                    <thead className="thead-dark">
                        <tr>
                            <th width="50%" className="align-middle">Guild</th>
                            <th width="50%">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {guildtable}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Guilds;