import { createTheme } from '@mui/material/styles';

export default createTheme({
    components: {
        MuiTooltip: {
            styleOverrides: {
                // match bootstrap .small
                tooltip: {
                    fontSize: '.875rem',
                    fontWeight: 400,
                    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    borderRadius: '0 !important'
                },
                input: {
                    padding: '4px 2px !important'
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    flex: '1 1 auto',
                    width: '1%'
                }
            }
        },
        MuiPopper: {
            styleOverrides: {
                root: {
                    zIndex: '200'
                }
            }
        }
    }
});