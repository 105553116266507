import React, { Component } from 'react';

import ToggleButton from '../components/ToggleButton';

class ToggleButtonWrapper extends Component {
    handleToggle = (event) => {
        if (this.props.id === 'All' || this.props.id === 'None') {
            this.props.onToggle(this.props.property, this.props.id, this.props.variants);
        } else {
            this.props.onToggle(this.props.property, this.props.variants || this.props.id);
        }
    }

    render() {
        if (this.props.id === 'All' || this.props.id === 'None') {
            return <button type="button" className="btn btn-primary" onClick={this.handleToggle}>{this.props.id}</button>;
        } else {
            return <ToggleButton label={this.props.label || this.props.id} checked={this.props.checked} onToggle={this.handleToggle} />;
        }
    }
}

export default ToggleButtonWrapper;