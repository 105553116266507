import React from 'react';

import './FactionIcon.scss';
import alliance from '../icons/alliance.svg';
import horde from '../icons/horde.svg';

function FactionIcon(props) {
    return <img className={'icon-' + props.faction} src={props.faction === 0 ? alliance : horde} alt={props.faction === 0 ? 'Alliance Only' : 'Horde Only'} />;
}

export default FactionIcon;