import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import utils from '../util/utils';

export default class Question extends Component {
    componentDidMount() {
        if (document.location.hash === ('#' + this.props.id)) {
            window.setTimeout(this.scrollTo, 250); // workaround for page loading/shifting
        }
    }

    scrollTo = () => {
        utils.scrollTo(this.props.id);
    }

    render() {
        return (
            <div className="mb-5">
                <h5 id={this.props.id}><a href={'#' + this.props.id}><FontAwesomeIcon icon={faQuestionCircle} /></a> {this.props.title}</h5>
                {this.props.children}
            </div>
        );
    }
}